export default [
  {
    id: "1",
    numDisquete: "15",
    diaDisquete: "2017-09-18T18:25:43.511Z",
    nomePraxe: "Tristão",
    questionario: null,
    primeiroNome: "Pedro",
    ultimoNome: "Henriques",
    outrosNomes: "Agostinho de Carvalho",
    foto: "/images/avatars/avatar_3.png",
    dataNascimento: "1999-07-20T18:25:43.511Z",
    telemovel: "934 141 919",
    telemovelEmergencia: "910 000 000",
    email: "pedroahenriques29@gmail.com",
    cidade: "Braga",
    morada: {
      freguesia: "Freguesia Teste",
      rua: "Rua Teste"
    },
    cartaoCidadao: null,
    facebookID: "100000477578820",
    numAluno: "A84794",
    horario: "A",
    autorizacaoPais: null,
    naPraxe: true,
    dataAntiPraxe: null,
    assinouFolha: false,
    folha: null
  },
  {
    id: "2",
    numDisquete: "17",
    diaDisquete: "18 de Setembro",
    nomePraxe: "Keeper",
    questionario: null,
    primeiroNome: "António",
    ultimoNome: "Gonçalves",
    outrosNomes: "Manuel Carvalho",
    foto: "/images/avatars/avatar_4.png",
    dataNascimento: "1999-02-22T18:25:43.511Z",
    telemovel: "933 373 633",
    email: "tonegone@gmail.com",
    cidade: "Bragança",
    morada: {
      freguesia: "Vila Flôr",
      rua: null
    },
    cartaoCidadao: null,
    facebookID: "100001179394388",
    numAluno: "A85516",
    horario: "H",
    autorizacaoPais: null,
    naPraxe: true,
    dataAntiPraxe: null,
    assinouFolha: false,
    folha: null
  },
  {
    id: "3",
    numDisquete: "22",
    diaDisquete: "18 de Setembro",
    nomePraxe: "Gordon",
    questionario: null,
    primeiroNome: "Gonçalo",
    ultimoNome: "Quesado",
    outrosNomes: "Nuno Esperança",
    foto: "/images/avatars/avatar_2.png",
    dataNascimento: "1999-02-19T18:25:43.511Z",
    telemovel: "967 681 055",
    email: "goncaloquesado@gmail.com",
    cidade: "Viana do Castelo",
    morada: {
      freguesia: "",
      rua: null
    },
    cartaoCidadao: null,
    facebookID: "100000766849883",
    numAluno: "A83657",
    horario: "H",
    autorizacaoPais: null,
    naPraxe: true,
    dataAntiPraxe: null,
    assinouFolha: false,
    folha: null
  },
  {
    id: "4",
    numDisquete: "24",
    diaDisquete: "2019-06-23T18:25:43.511Z",
    nomePraxe: "Sentinela",
    questionario: null,
    primeiroNome: "Ricardo",
    ultimoNome: "Costa",
    outrosNomes: "Filipe Dantas",
    foto: "/images/avatars/avatar_5.png",
    dataNascimento: "1999-05-07T18:25:43.511Z",
    telemovel: "919 741 317",
    email: "ricardofdcosta@gmail.com",
    cidade: "Braga",
    morada: {
      freguesia: "S. João do Souto",
      rua: null
    },
    cartaoCidadao: null,
    facebookID: "100001711626720",
    numAluno: "A85851",
    horario: "D",
    autorizacaoPais: null,
    naPraxe: true,
    dataAntiPraxe: null,
    assinouFolha: false,
    folha: null
  }
];
