import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Link, Button, Typography } from "@material-ui/core";
import { ArrowRightOutlined } from "@material-ui/icons";
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {},
  portletContent: {
    paddingTop: "0"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  product: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    "&:not(:first-of-type)": {
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  productImageWrapper: {
    borderRadius: "5px",
    overflow: "hidden",
    height: "64px",
    width: "64px"
  },
  productImage: {
    width: "100%",
    height: "auto"
  },
  productDetails: {
    marginLeft: theme.spacing(2),
    flexGrow: 1
  },
  productTitle: {},
  productTimestamp: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const Planeamento = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletHeader noDivider>
        <PortletLabel title="Planeamento Semanal" />
      </PortletHeader>
      <PortletContent className={classes.portletContent}>
        <div className={classes.product}>
          <div className={classes.productDetails}>
            <Link to="#">
              <Typography className={classes.productTitle} variant="h5">
                Segunda-feira, 21 de Outubro
              </Typography>
            </Link>
            <Typography className={classes.productTimestamp} variant="body1">
              Planeamento
            </Typography>
          </div>
        </div>
        <div className={classes.product}>
          <div className={classes.productDetails}>
            <Link to="#">
              <Typography className={classes.productTitle} variant="h5">
                Terça-feira, 22 de Outubro
              </Typography>
            </Link>
            <Typography className={classes.productTimestamp} variant="body1">
              Planeamento
            </Typography>
          </div>
        </div>
        <div className={classes.product}>
          <div className={classes.productDetails}>
            <Link to="#">
              <Typography className={classes.productTitle} variant="h5">
                Quarta-feira, 23 de Outubro
              </Typography>
            </Link>
            <Typography className={classes.productTimestamp} variant="body1">
              Planeamento
            </Typography>
          </div>
        </div>
        <div className={classes.product}>
          <div className={classes.productDetails}>
            <Link to="#">
              <Typography className={classes.productTitle} variant="h5">
                Quinta-feira, 24 de Outubro
              </Typography>
            </Link>
            <Typography className={classes.productTimestamp} variant="body1">
              Planeamento
            </Typography>
          </div>
        </div>
        <div className={classes.product}>
          <div className={classes.productDetails}>
            <Link to="#">
              <Typography className={classes.productTitle} variant="h5">
                Sexta-feira, 25 de Outubro
              </Typography>
            </Link>
            <Typography className={classes.productTimestamp} variant="body1">
              Planeamento
            </Typography>
          </div>
        </div>
      </PortletContent>
      <PortletFooter className={classes.portletFooter}>
        <Button color="primary" size="small" variant="text">
          Ver última reunião <ArrowRightOutlined />
        </Button>
      </PortletFooter>
    </Portlet>
  );
};

export default Planeamento;
