import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  details: {
    display: "flex"
  },
  info: {},
  locationText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    marginLeft: "auto",
    height: "150px",
    width: "150px",
    flexShrink: 0,
    flexGrow: 0
  },
  progressWrapper: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const EditarLocalizacao = props => {
  const {
    open,
    selectedLocalId,
    onHandleSelect,
    onHandleOpen,
    onHandleClose
  } = props;

  const classes = useStyles();

  const [data, setData] = useState({ locais: [] });

  useEffect(() => {
    const fetchLocais = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      var resultado = await fetch(`${API_URL}/v1/locais`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      // Ordena a lista de locais por ordem alfabética
      resultado = resultado.sort(function(a, b){
        var textA = a.local.toUpperCase();
        var textB = b.local.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      setData({
        locais: resultado
      });
    };
    fetchLocais();
  }, []);

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={onHandleOpen}>
        Editar
      </Button>
      <Dialog
        open={open}
        onClose={event => onHandleClose(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={false}
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">
          Editar Localização do Bloco
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Indica a localização atual do bloco.
          </DialogContentText>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth={true}
          >
            <Select
              value={selectedLocalId}
              onChange={onHandleSelect}
              input={<OutlinedInput labelWidth={"Localização".offsetWidth} />}
            >
              {Array.from(data.locais).map((local, i) => (
                <MenuItem key={local.id} value={local.id}>{local.local}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => onHandleClose(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={event => onHandleClose(true)} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(EditarLocalizacao);
