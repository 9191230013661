import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import {
  Portlet,
  PortletHeader,
  PortletContent,
  PortletFooter
} from "../../../../components";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Typography
} from "@material-ui/core";
import { checkNullReturnBlank } from '../../../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(0)
  },
  menu: {
    width: 200
  },
  margin: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  firstCategory: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2),
    marginTop: theme.spacing(0)
  },
  category: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1)
  },
  avatar: {
    marginBottom: theme.spacing(2),
    height: "125px",
    width: "125px",
    flexShrink: 0,
    flexGrow: 0
  },
  footer: {
    display: "flex",
    flexDirection: "row-reverse"
  }
}));

const DadosPessoais = props => {
  const { values, onHandleSelect, onHandleDataNascimento } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          disabled
          label="Número de Disquete"
          margin="dense"
          name="numDisquete"
          onChange={onHandleSelect}
          value={values.numDisquete}
          variant="outlined"
          error={values.numDisquete.length === 0 ? true : false}
          helperText={values.numDisquete.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Nome de Praxe"
          margin="dense"
          name="nomePraxe"
          onChange={onHandleSelect}
          value={values.nomePraxe}
          variant="outlined"
          error={values.nomePraxe.length === 0 ? true : false}
          helperText={values.nomePraxe.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Primeiro Nome"
          margin="dense"
          name="primeiroNome"
          onChange={onHandleSelect}
          value={values.primeiroNome}
          variant="outlined"
          error={values.primeiroNome.length === 0 ? true : false}
          helperText={values.primeiroNome.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Último Nome"
          margin="dense"
          name="ultimoNome"
          onChange={onHandleSelect}
          value={values.ultimoNome}
          variant="outlined"
          error={values.ultimoNome.length === 0 ? true : false}
          helperText={values.ultimoNome.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Outros Nomes"
          margin="dense"
          name="outrosNomes"
          onChange={onHandleSelect}
          value={values.outrosNomes}
          variant="outlined"
          error={values.outrosNomes.length === 0 ? true : false}
          helperText={values.outrosNomes.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin="dense"
            inputVariant="outlined"
            label="Data de Nascimento"
            name="dataNascimento"
            value={values.dataNascimento}
            onChange={onHandleDataNascimento}
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            error={values.dataNascimento.length === 0 ? true : false}
            helperText={values.dataNascimento.length === 0 ? "Campo de preenchimento obrigatório" : ""}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

const MoradaContactos = props => {
  const { values, onHandleSelect } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Cidade"
          margin="dense"
          name="cidade"
          onChange={onHandleSelect}
          value={values.cidade}
          variant="outlined"
          error={values.cidade.length === 0 ? true : false}
          helperText={values.cidade.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Freguesia"
          margin="dense"
          name="freguesia"
          onChange={onHandleSelect}
          value={values.freguesia}
          variant="outlined"
          error={values.freguesia.length === 0 ? true : false}
          helperText={values.freguesia.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Rua"
          margin="dense"
          name="rua"
          onChange={onHandleSelect}
          value={values.rua}
          variant="outlined"
          error={values.rua.length === 0 ? true : false}
          helperText={values.rua.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Email"
          margin="dense"
          name="email"
          onChange={onHandleSelect}
          value={values.email}
          variant="outlined"
          error={values.email.length === 0 ? true : false}
          helperText={values.email.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Telemóvel"
          margin="dense"
          name="telemovel"
          onChange={onHandleSelect}
          value={values.telemovel}
          variant="outlined"
          error={values.telemovel.length === 0 ? true : false}
          helperText={values.telemovel.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Facebook ID"
          margin="dense"
          name="facebookID"
          onChange={onHandleSelect}
          value={values.facebookID}
          variant="outlined"
          error={values.facebookID.length === 0 ? true : false}
          helperText={values.facebookID.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
    </Grid>
  );
};

const UniversidadePraxe = props => {
  const { values, onHandleSelect } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Número de Aluno"
          margin="dense"
          name="numAluno"
          onChange={onHandleSelect}
          value={values.numAluno}
          variant="outlined"
          error={values.numAluno.length === 0 ? true : false}
          helperText={values.numAluno.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          select
          label="Grau Hierárquico"
          name="grauHierarquico"
          value={values.grauHierarquico}
          onChange={onHandleSelect}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
          error={values.grauHierarquico.length === 0 ? true : false}
          helperText={values.grauHierarquico.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        >
          <MenuItem value="Engenheiro">Engenheiro</MenuItem>
          <MenuItem value="Bispo">Bispo</MenuItem>
          <MenuItem value="Cardeal">Cardeal</MenuItem>
          <MenuItem value="Cardeal de Curso">Cardeal de Curso</MenuItem>
          <MenuItem value="Super-Engenheiro">Super-Engenheiro</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

const EditarPerfil = props => {
  const { className, user, onHandleConfirmar } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    numDisquete: checkNullReturnBlank(user.numDisquete),
    nomePraxe: checkNullReturnBlank(user.nomePraxe),
    primeiroNome: checkNullReturnBlank(user.primeiroNome),
    ultimoNome: checkNullReturnBlank(user.ultimoNome),
    outrosNomes: checkNullReturnBlank(user.outrosNomes),
    foto: checkNullReturnBlank(user.foto),
    dataNascimento: checkNullReturnBlank(user.dataNascimento),
    telemovel: checkNullReturnBlank(user.telemovel),
    email: checkNullReturnBlank(user.email),
    cidade: checkNullReturnBlank(user.cidade),
    freguesia: checkNullReturnBlank(user.freguesia),
    rua: checkNullReturnBlank(user.rua),
    facebookID: checkNullReturnBlank(user.facebookID),
    numAluno: checkNullReturnBlank(user.numAluno),
    grauHierarquico: checkNullReturnBlank(user.grauHierarquico)
  })

  const handleSelect = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDataNascimento = dataNascimento => {
    setValues({
      ...values,
      dataNascimento: dataNascimento
    });
  };

  const handleCancelar = () => {
    setValues({
      ...values,
      numDisquete: checkNullReturnBlank(user.numDisquete),
      nomePraxe: checkNullReturnBlank(user.nomePraxe),
      primeiroNome: checkNullReturnBlank(user.primeiroNome),
      ultimoNome: checkNullReturnBlank(user.ultimoNome),
      outrosNomes: checkNullReturnBlank(user.outrosNomes),
      foto: checkNullReturnBlank(user.foto),
      dataNascimento: checkNullReturnBlank(user.dataNascimento),
      telemovel: checkNullReturnBlank(user.telemovel),
      email: checkNullReturnBlank(user.email),
      cidade: checkNullReturnBlank(user.cidade),
      freguesia: checkNullReturnBlank(user.freguesia),
      rua: checkNullReturnBlank(user.rua),
      facebookID: checkNullReturnBlank(user.facebookID),
      numAluno: checkNullReturnBlank(user.numAluno),
      grauHierarquico: checkNullReturnBlank(user.grauHierarquico)
    })
  }

  return (
    <Portlet className={clsx(classes.root, className)}>
      <PortletHeader>
        <Typography variant="h5">Editar Perfil</Typography>
      </PortletHeader>
      <PortletContent>
        <Typography
          variant="h6"
          align="center"
          className={classes.firstCategory}
        >
          Dados Pessoais
        </Typography>
        <DadosPessoais
          values={values}
          onHandleSelect={handleSelect}
          onHandleDataNascimento={handleDataNascimento}
        />
        <Typography variant="h6" align="center" className={classes.category}>
          Morada e Contactos
        </Typography>
        <MoradaContactos
          values={values}
          onHandleSelect={handleSelect}
        />
        <Typography variant="h6" align="center" className={classes.category}>
          Universidade e Praxe
        </Typography>
        <UniversidadePraxe
          values={values}
          onHandleSelect={handleSelect}
        />
      </PortletContent>
      <PortletFooter className={classes.footer}>
        <Button
          onClick={event => onHandleConfirmar(true, values)}
          color="primary"
          disabled={(
               values.numDisquete.length === 0
            || values.nomePraxe.length === 0
            || values.primeiroNome.length === 0
            || values.ultimoNome.length === 0
            || values.outrosNomes.length === 0
            || values.dataNascimento.length === 0
            || values.cidade.length === 0
            || values.freguesia.length === 0
            || values.rua.length === 0
            || values.email.length === 0
            || values.telemovel.length === 0
            || values.facebookID.length === 0
            || values.numAluno.length === 0
            || values.grauHierarquico.length === 0
            )? true : false}
        >
          Confirmar
        </Button>
        <Button onClick={event => onHandleConfirmar(false)} color="primary">
          Cancelar
        </Button>
      </PortletFooter>
    </Portlet>
  );
};

export default EditarPerfil;
