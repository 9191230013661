import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './helpers/privateRoute'
import { withRouter } from "react-router-dom";

import IniciarSessao from './views/iniciarSessao';
import Dashboard from './views/dashboard';
import Bestas from './views/bestas'
import Engenheiros from './views/engenheiros'
import Estatisticas from './views/estatisticas'
import Calendario from './views/calendario'
import Praxes from './views/praxes'
import Reunioes from './views/reunioes'
import Topicos from './views/topicos'
import Locais from './views/locais'
import Perfil from './views/perfil'
import Definicoes from './views/definicoes'
import Admin from './views/admin'

const Router = props => {

  const [values, setValues] = useState({
    selectedBestaId: '',
    selectedEngenheiroId: '',
    selectedPraxeId: '',
    selectedLocalId: '',
  });

  useEffect(() => {
    const fetchBestas = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/getSelectedIds`, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
      setValues({
        ...values,
        selectedBestaId: resultado.idBesta,
        selectedEngenheiroId: resultado.idEngenheiro,
        selectedPraxeId: resultado.idPraxe,
        selectedLocalId: resultado.idLocal,
      });
    };
    fetchBestas();
  }, [])

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />

      <Route
        component={IniciarSessao}
        exact
        path="/iniciar-sessao"
      />
      <PrivateRoute
        component={Dashboard}
        exact
        path="/dashboard"
      />
      <PrivateRoute
        component={Bestas}
        exact
        path="/bestas/:id"
      />
      <Redirect from='/bestas' to={{pathname: '/bestas/' + values.selectedBestaId}}/>
      <PrivateRoute
        component={Engenheiros}
        exact
        path="/engenheiros/:id"
      />
      
      <Redirect from='/engenheiros' to={{pathname: '/engenheiros/' + values.selectedEngenheiroId}}/>
      <PrivateRoute
        component={Estatisticas}
        exact
        path="/estatisticas"
      />
      <PrivateRoute
        component={Calendario}
        exact
        path="/calendario"
      />
      <PrivateRoute
        component={Praxes}
        exact
        path="/praxes/:id"
      />
      <Redirect from='/praxes' to={{pathname: '/praxes/' + values.selectedPraxeId}}/>
      <PrivateRoute
        component={Reunioes}
        exact
        path="/reunioes/:id"
      />
      <Redirect from='/reunioes' to='/reunioes/1'/>
      <PrivateRoute
        component={Topicos}
        exact
        path="/topicos/:id"
      />
      <Redirect from='/topicos' to='/topicos/1'/>
      <PrivateRoute
        component={Locais}
        exact
        path="/locais/:id"
      />
      <Redirect from='/locais' to={{pathname: '/locais/' + values.selectedLocalId}}/>
      <PrivateRoute
        component={Perfil}
        exact
        path="/perfil"
      />
      <PrivateRoute
        component={Definicoes}
        exact
        path="/definicoes"
      />
      <PrivateRoute
        component={Admin}
        exact
        path="/admin"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
}

export default withRouter(Router)
