import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import data from "../../data/praxes";
import {
  ProcurarReunioes,
  EditarReuniao,
  ListaReunioes,
  Detalhes,
  Comentarios,
  Presencas,
  Faltas
} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  list: {
    marginTop: theme.spacing(2)
  },
  editarButton: {
    marginRight: theme.spacing(1)
  }
}));

const Reunioes = props => {
  const classes = useStyles();

  // const api = useContext(MyContext)

  const [values, setValues] = useState({
    openEditar: false,
    openCriar: false,
    praxes: data,
    selectedIndex: 0,
    selectedPraxeId: "1"
  });

  useEffect(() => {
    var id = props.match.params.id;

    setValues({
      openEditar: false,
      openCriar: false,
      praxes: data,
      selectedIndex: id - 1,
      selectedPraxeId: id
    });
  }, [props.match.params.id]);

  /*
  useEffect(() => {
    var id = props.match.params.id;

    fetch('http://127.0.0.1:3333/api/v1' + 'reunioes')
      .then(response => response.json())
      .then(data => setValues ({
        reunioes: data,
        selectedIndex: id - 1,
        selectedEngenheiroId: id,
        open: false,
      }))
  })*/

  const handleSelect = (selectedIndex, selectedPraxeId) => {
    setValues({
      praxes: data,
      selectedIndex,
      selectedPraxeId
    });
    props.history.push("/reunioes/" + selectedPraxeId);
  };

  const handleOpenEditar = () => {
    setValues({
      ...values,
      openEditar: true
    });
  };

  const handleCloseEditar = confirmar => {
    setValues({
      ...values,
      openEditar: false
    });
    if (confirmar) {
      setValues({
        ...values,
        openEditar: false
      });
    }
  };

  /*
  const handleOpenCriar = () => {
    setValues({
      ...values,
      openCriar: true,
    });
  };

  const handleCloseCriar = confirmar => {
    setValues({
      ...values,
      openCriar: false
    });
    if(confirmar) {
      setValues({
        ...values,
        openCriar: false
      });
    }
  };*/

  return (
    <DashboardLayout title="Reuniões">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={3} sm={4} xl={3} xs={5}>
            <ProcurarReunioes selectedBesta={values.selectedBesta} />
          </Grid>
          <Grid
            item
            lg={9}
            sm={8}
            xl={9}
            xs={7}
            align="right"
            container
            justify="flex-end"
          >
            <EditarReuniao
              open={values.openEditar}
              onHandleOpen={handleOpenEditar}
              onHandleClose={handleCloseEditar}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            <ListaReunioes
              onHandleSelect={handleSelect}
              selectedIndex={values.selectedIndex}
              praxes={values.praxes}
            />
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={12}>
            <Detalhes
              onHandleSelect={handleSelect}
              selectedPraxeId={values.selectedPraxeId}
              praxes={values.praxes}
            />
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Comentarios
                className={classes.item}
                selectedPraxeId={values.selectedPraxeId}
                praxes={values.praxes}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Presencas className={classes.item} />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Faltas className={classes.item} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

Reunioes.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withRouter(Reunioes);
