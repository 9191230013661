import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";

import { Notificacoes, AlterarPassword } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Settings = () => {
  const classes = useStyles();

  return (
    <DashboardLayout title="Definições">
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item md={7} xs={12}>
            <Notificacoes />
          </Grid>
          <Grid item md={5} xs={12}>
            <AlterarPassword />
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
