import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Portlet, PortletContent } from "../../../../components";
import { Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  chartContainer: {
    position: "relative",
    height: "300px"
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  stats: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center"
  },
  device: {
    textAlign: "center",
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const DesistenciasDiaSemana = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [values, setValues] = useState({
    desistencias: []
  })

  useEffect(() => {
    const fetchData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/bestas/desistenciasDiaSemana`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        desistencias: resultado
      });
    }
    fetchData();
  }, [])

  const data = {
    datasets: [
      {
        data: values.desistencias,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(75, 192, 192)",
          "rgb(255, 205, 86)",
          "rgb(201, 203, 207)",
          "rgb(54, 162, 235)"
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"]
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const dias = [
    {
      title: "Segunda",
      value: values.desistencias[0],
      color: "rgb(255, 99, 132)"
    },
    {
      title: "Terça",
      value: values.desistencias[1],
      color: "rgb(75, 192, 192)"
    },
    {
      title: "Quarta",
      value: values.desistencias[2],
      color: "rgb(255, 205, 86)"
    },
    {
      title: "Quinta",
      value: values.desistencias[3],
      color: "rgb(201, 203, 207)"
    },
    {
      title: "Sexta",
      value: values.desistencias[4],
      color: "rgb(54, 162, 235)"
    }
  ];

  const loading = values.desistencias.length == 0

  return (
    <Portlet className={clsx(classes.root, className)}>
    { loading ? <CircularProgress />
    :  <PortletContent>
        <Typography className={classes.title} variant="h5">
          Desistências por Dia da Semana
        </Typography>
        <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={classes.stats}>
          {dias.map(dia => (
            <div className={classes.device} key={dia.title}>
              <span className={classes.deviceIcon}>{dia.icon}</span>
              <Typography variant="body1">{dia.title}</Typography>
              <Typography style={{ color: dia.color }} variant="h2">
                {dia.value}%
              </Typography>
            </div>
          ))}
        </div>
      </PortletContent>
    }
    </Portlet>
  );
};

export default withRouter(DesistenciasDiaSemana);
