import React from "react";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Typography
} from "@material-ui/core";
import { Portlet, PortletContent } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 470,
    overflow: "auto"
  },
  tableRow: {
    height: "64px"
  },
  scroll: {
    maxHeight: "100%",
    overflow: "auto"
  },
  tableCell: {
    whiteSpace: "nowrap"
  },
  tableCellInner: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px"
  },
  nameText: {
    display: "inline-block",
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: "pointer"
  }
}));

const ListaPraxes = props => {
  const { className, selectedIndex, onHandleSelect, praxes } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <List className={classes.root}>
            {praxes.map((praxe, i) => (
              <ListItem
                button
                selected={selectedIndex === i}
                onClick={event => onHandleSelect(i, praxe.id)}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <Moment format="ddd">{praxe.data}</Moment>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.nameText} variant="h5">
                      {praxe.nome}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
};
ListaPraxes.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  bestas: PropTypes.array.isRequired
};

ListaPraxes.defaultProps = {
  bestas: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

export default ListaPraxes;
