import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "../../../../components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

export default function CenteredTabs(props) {
  const classes = useStyles();
  const { tab, onHandleTabChange } = props;

  return (
    <Paper className={classes.root}>
      <Tabs
        value={tab}
        onChange={onHandleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Bestas" />
        <Tab label="Engenheiros" />
      </Tabs>
    </Paper>
  );
}
