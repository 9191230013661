import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { Portlet, PortletContent, PortletFooter } from "../../components";

import './main.scss'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Calendario = props => {
  const classes = useStyles();

  return (
    <DashboardLayout title="Calendário">
      <div className={classes.root}>
        <Portlet>
          <PortletContent>
            <FullCalendar
              locale='pt-pt'
              defaultView="dayGridMonth"
              plugins={[ dayGridPlugin, googleCalendarPlugin ]}
              googleCalendarApiKey='AIzaSyC9Hia5rHK0Ml1lBsRv9O2v6V-iBgC_YQQ'
              events={{googleCalendarId:'232233826854-pml4868dl6ks53euije57lm573qr446c.apps.googleusercontent.com'}}
            />
          </PortletContent>
        </Portlet>
      </div>
    </DashboardLayout>
  );
};

export default withRouter(Calendario);
