export default [
  {
    id: "1",
    data: "2018-09-24T18:25:43.511Z",
    tipo: "Especial",
    nome: "24 de Setembro",
    planeamento: [
      "Fazer estandarte para a Latada",
      "Comprar as pulseiras para a Receção"
    ],
    presencas: {
      manha: ["15", "17"],
      tarde: ["15", "17"],
      jantar: ["15"]
    },
    comentarios: "Isto é um comentário.",
    report:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  },
  {
    id: "2",
    data: "2018-09-20T18:25:43.511Z",
    tipo: "Normal",
    nome: "20 de Setembro",
    planeamento: [
      "Treinar o Haka",
      "Ensinar as musicolas",
      "Falar sobre a eleição do delegado"
    ],
    presencas: {
      manha: [
        {
          id: "1",
          numDisquete: "15",
          foto: "/images/avatars/avatar_3.png"
        },
        {
          id: "2",
          numDisquete: "17",
          foto: "/images/avatars/avatar_4.png"
        }
      ],
      tarde: [],
      jantar: []
    },
    antiPraxe: [{}],
    comentarios: "Isto é um comentário.",
    report:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  }
];
