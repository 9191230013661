import React, { useState } from "react";
import Moment from "react-moment";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Portlet, PortletContent, PortletFooter } from "../../../../components";
import { EditarPerfil } from "./components";
import { SocialIcon } from "react-social-icons";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CreateIcon from "@material-ui/icons/Create";
import GroupIcon from "@material-ui/icons/Group";
import { Avatar, Typography, Grid } from "@material-ui/core";
import { validateDataReact } from '../../../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    marginTop: theme.spacing(1),
    display: "flex"
  },
  text: {
    color: theme.palette.text.secondary
  },
  avatar: {
    marginLeft: "auto",
    height: "150px",
    width: "150px"
  },
  iconBackground: {
    marginLeft: 5,
    backgroundColor: theme.palette.primary.main,
    height: 30,
    width: 30,
    alignItems: "center"
  },
  icon: {
    height: 15,
    width: 15
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
}));

const PrimeiraColuna = props => {
  const { besta } = props;

  const classes = useStyles();

  return (
    <Grid item md={12} xs={12} sm={12} lg={7}>
      <Typography className={classes.text} variant="body1">
        <strong>
          {besta.primeiroNome} {besta.ultimoNome}
        </strong>{" "}
        {besta.outrosNomes}
      </Typography>
      <Typography className={classes.text} variant="body1">
        <Moment diff={validateDataReact(besta.dataNascimento)} unit="years">
          {new Date()}
        </Moment>{" "}
        anos (<Moment format="D [de] MMMM">{validateDataReact(besta.dataNascimento)}</Moment>)
      </Typography>
      <Typography className={classes.text} variant="body1">
        Entrou a <Moment format="D [de] MMMM">{besta.diaDisquete}</Moment>
      </Typography>
      <Typography className={classes.text} variant="body1">
        {besta.cidade}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {besta.freguesia}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {besta.rua}
      </Typography>
    </Grid>
  );
};

const SegundaColuna = props => {
  const { besta } = props;

  const classes = useStyles();

  return (
    <Grid item md={12} xs={12} sm={12} lg={5}>
      <Typography className={classes.text} variant="body1">
        {besta.telemovel}
      </Typography>
      <Typography className={classes.text} variant="body1">
      { besta.telemovelEmergencia !== null && besta.telemovelEmergencia !== ''
        ? besta.telemovelEmergencia + " (Emg.)"
        : ''
      }
      </Typography>
      <Typography className={classes.text} variant="body1">
        {besta.email}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {besta.numAluno}
      </Typography>
      <Typography className={classes.text} variant="body1">
        Horário {besta.horario}
      </Typography>
    </Grid>
  );
};

const Perfil = props => {
  const { className, openEditar, selectedBestaId, bestas, onHandleOpenEditar, onHandleCloseEditar, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
    const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

  const besta = bestas.find(
    besta => besta.id == selectedBestaId
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [besta.foto, besta.autorizacaoPais, besta.questionario, besta.folha];
  const infoTitle = ["Foto", "Autorização dos Pais", "Questionário", "Folha de AP"];

  const handleFileCopyIconClick = () => {
    // Update the current image index when the button is clicked
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };


  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletContent>
        <div className={classes.header}>
          <Typography variant="h2" style={{ marginRight: 10 }}>
            {besta.numDisquete} {besta.nomePraxe}
          </Typography>
          { !besta.naPraxe
            ? <Typography variant="h4" style={{ marginRight: 10, color: "rgb(255, 99, 132)" }}>
                Anti-Praxe (<Moment format="D [de] MMMM">{validateDataReact(besta.dataAP)}</Moment>)
              </Typography>
            : null
          }
          <SocialIcon url={"https://facebook.com/" + besta.facebookID} style={{ height: 30, width: 30 }} />
          <Avatar className={classes.iconBackground}>
            <FileCopyIcon className={classes.icon} onClick={handleFileCopyIconClick} />
          </Avatar>
          <Typography align="right" variant="body1" className={classes.text}>
            <strong>
              {infoTitle[currentImageIndex]}
            </strong>
          </Typography>
          <Avatar className={classes.iconBackground}>
            <CreateIcon className={classes.icon} />
          </Avatar>
          <Avatar className={classes.iconBackground}>
            <GroupIcon className={classes.icon} />
          </Avatar>
        </div>
        <div className={classes.details}>
          <div>
            <Grid container spacing={3}>
              <PrimeiraColuna className={className} besta={besta} />
              <SegundaColuna className={className} besta={besta} />
            </Grid>
          </div>
          <Avatar className={classes.avatar} src={IMAGES_URL + images[currentImageIndex]} />
        </div>
      </PortletContent>
      <PortletFooter>
        <EditarPerfil
          className={classes.item}
          besta={besta}
          openEditar={openEditar}
          onHandleOpenEditar={onHandleOpenEditar}
          onHandleCloseEditar={onHandleCloseEditar}
        />
      </PortletFooter>
    </Portlet>
  );
};

export default Perfil;
