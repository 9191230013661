import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import {
  ProcurarEngenheiros,
  ListaEngenheiros,
  Perfil,
  PresencasReunioes,
  Quotes,
  AdicionarEngenheiro
} from "./components";
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateDataSQL } from '../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Engenheiros = props => {
  const classes = useStyles();

  const [values, setValues] = useState({
    selectedIndex: '',
    selectedEngenheiroId: '',
    openAdicionar: false,
    openEditar: false,
    update: false,
    filtro: ''
  });

  const [data, setData] = useState({ engenheiros: [] });

  const sortOrder = [
    'Engenheiro',
    'Bispo',
    'Cardeal',
    'Cardeal de Curso',
    'Super-Engenheiro'
  ];

  useEffect(() => {
    var id = props.match.params.id;

    const fetchEngenheiros = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/engenheiros`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      // Ordena a lista de engenheiros por ordem de disquete
      resultado.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      // Posteriormente, ordena a lista de engenheiros por grau hierárquico
      resultado.sort(function(a, b) {
        return sortOrder.indexOf(a.grauHierarquico) - sortOrder.indexOf(b.grauHierarquico);
      })

      // De acordo com o id do engenheiro, retorna o índice da lista
      // em que este se encontra (necessário para saber qual o elemento deverá
      // estar selecionado)
      const index = resultado.findIndex(function(item, i){
        return item.id == id
      })

      setValues({
        ...values,
        selectedIndex: index,
        selectedEngenheiroId: id
      });

      setData({
        ...data,
        engenheiros: resultado
      });
    };
    fetchEngenheiros();
  }, [values.update]);

  const handleSelect = (selectedIndex, selectedEngenheiroId) => {
    setValues({
      ...values,
      selectedIndex,
      selectedEngenheiroId,
    });
    props.history.push("/engenheiros/" + selectedEngenheiroId);
  };

  const handleSelectFiltro = event => {
    setValues({
      ...values,
      filtro: event.target.value
    })
  }

  const handleOpenAdicionar = (confirmar, engenheiro) => {
    setValues({
      ...values,
      openAdicionar: true
    });
  };

  const handleCloseAdicionar = async (confirmar, engenheiro) => {
    if(confirmar) {
      // Os atributos do engenheiro a adicionar são definidos no componente
      // Adicionar Engenheiro. Este passa-os como argumento no objeto "engenheiro",
      // juntamente com a flag "confirmar", que indica se o engenheiro deve ser
      // inserido na base de dados ou não
      
      const formData = new FormData();
      
      formData.append('numDisquete', parseInt(engenheiro.numDisquete))
      formData.append('nomePraxe', engenheiro.nomePraxe)
      formData.append('primeiroNome', engenheiro.primeiroNome)
      formData.append('ultimoNome', engenheiro.ultimoNome)
      formData.append('outrosNomes', engenheiro.outrosNomes)
      formData.append('dataNascimento', validateDataSQL(engenheiro.dataNascimento))
      formData.append('telemovel', engenheiro.telemovel)
      formData.append('email', engenheiro.email)
      formData.append('cidade', engenheiro.cidade)
      formData.append('freguesia', engenheiro.freguesia)
      formData.append('rua', engenheiro.rua)
      formData.append('facebookID', engenheiro.facebookID)
      formData.append('numAluno', engenheiro.numAluno)
      formData.append('grauHierarquico', engenheiro.grauHierarquico)
      formData.append('password', engenheiro.password)

      if(engenheiro.foto !== null && engenheiro.foto !== undefined)
        formData.append("foto", engenheiro.foto);

      const API_URL = process.env.REACT_APP_API_URL;
     
      fetch(`${API_URL}/v1/engenheiros`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
        },
        body: formData
      })
        .then(response => {
          console.log(response.body)
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Adicionar Besta
    setValues({
      ...values,
      update: !values.update,
      openAdicionar: false,
    })
  };

  const handleOpenEditar = () => {
    setValues({
      ...values,
      openEditar: true
    })
  }

  const handleCloseEditar = async (confirmar, engenheiro) => {
    // Só pode editar o seu perfil
    var check_id = JSON.parse(localStorage.getItem('user')).id == values.selectedEngenheiroId ? true : false
    
    if(confirmar && check_id) {
      // Os atributos do engenheiro a editar são definidos no componente
      // Editar Perfil. Este passa-os como argumento no objeto "engenheiro",
      // juntamente com a flag "confirmar", que indica se o engenheiro deve ser
      // editado na base de dados ou não
      
      const formData = new FormData();

      console.log(JSON.stringify(engenheiro))
      formData.append('numDisquete', parseInt(engenheiro.numDisquete))
      formData.append('nomePraxe', engenheiro.nomePraxe)
      formData.append('primeiroNome', engenheiro.primeiroNome)
      formData.append('ultimoNome', engenheiro.ultimoNome)
      formData.append('outrosNomes', engenheiro.outrosNomes)
      if(engenheiro.dataNascimento != "")
        formData.append('dataNascimento', validateDataSQL(engenheiro.dataNascimento))
      formData.append('telemovel', engenheiro.telemovel)
      formData.append('email', engenheiro.email)
      formData.append('cidade', engenheiro.cidade)
      formData.append('freguesia', engenheiro.freguesia)
      formData.append('rua', engenheiro.rua)
      formData.append('facebookID', engenheiro.facebookID)
      formData.append('numAluno', engenheiro.numAluno)
      formData.append('grauHierarquico', engenheiro.grauHierarquico)
      //formData.append('password', engenheiro.password)

      if(engenheiro.foto !== null && engenheiro.foto !== undefined && !(typeof engenheiro.foto === "string"))
        formData.append("foto", engenheiro.foto);

      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/engenheiros/${values.selectedEngenheiroId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
        },
        body: formData
      })
        .then(response => {
          console.log(response)
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          response.json()
        .then(data => {
          const user = {
            id: JSON.parse(localStorage.getItem("user")).id,
            foto: data.foto,
            nomePraxe: JSON.parse(localStorage.getItem("user")).nomePraxe,
            grauHierarquico: JSON.parse(localStorage.getItem("user")).grauHierarquico,
          }
          if(engenheiro.nomePraxe === user.nomePraxe) {
            localStorage.setItem("user", JSON.stringify(user))
          }
          })
        })
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardado
    // no estado da componente e fechamos a página de Editar Perfil
    setValues({
      ...values,
      update: !values.update,
      openEditar: false,
    })
  }

  const loading = data.engenheiros.length === 0;

  return (
    <DashboardLayout title="Engenheiros">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={3} sm={4} xl={3} xs={9}>
            <ProcurarEngenheiros
              filtro={values.filtro}
              onHandleSelectFiltro={handleSelectFiltro}
            />
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={3} align="right">
            {loading
              ? <CircularProgress/>
              : <AdicionarEngenheiro
                  openAdicionar={values.openAdicionar}
                  onHandleOpenAdicionar={handleOpenAdicionar}
                  onHandleCloseAdicionar={handleCloseAdicionar}
                  {...values}
              />
            }
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            {loading
              ? <CircularProgress />
              : <ListaEngenheiros
                  onHandleSelect={handleSelect}
                  selectedIndex={values.selectedIndex}
                  filtro={values.filtro}
                  engenheiros={data.engenheiros}
                />
            }
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={12}>
            {loading
              ? <CircularProgress />
              : <Perfil
                  openEditar={values.openEditar}
                  onHandleOpenEditar={handleOpenEditar}
                  onHandleCloseEditar={handleCloseEditar}
                  selectedEngenheiroId={values.selectedEngenheiroId}
                  engenheiros={data.engenheiros}
                  />
            }
            {/*<Grid item lg={12} sm={12} xl={12} xs={12}>
              {loading
                ? <CircularProgress />
                : <PresencasReunioes className={classes.item} />
              }
            </Grid>*/}
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              {/*<Quotes className={classes.item} */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default withRouter(Engenheiros);
