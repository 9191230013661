import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Dashboard as DashboardLayout } from "../../layouts";
import {
  Portlet,
  PortletHeader,
  PortletContent,
  PortletFooter
} from "../../components";
import { Button, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}));

const Admin = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    person: "",
    currentPassword: "",
    newPassword: ""
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleConfirmar = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    fetch(`${API_URL}/v1/engenheiros/changePassword`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'person': values.person,
        'newPassword': values.newPassword
      })
    })
    .then(async response => {
      const responsePayload = await response.json();

      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (responsePayload && responsePayload.message) || "Error";
        return Promise.reject(error);
      }
      setValues({
        ...values,
        person: "",
        newPassword: ""
      });

      alert("Palavra-passe de: " + values.person + " alterada com sucesso!");
      
    })
    .catch((error) => {
      alert( JSON.stringify( error ));
    })
  }

  return (
    <DashboardLayout title = "Admin">
        <div className={classes.root}>
            <Portlet {...rest} className={clsx(classes.root, className)}>
            <form>
                <PortletHeader>
                <Typography variant="h5">Alterar Palavra-passe</Typography>
                </PortletHeader>
                <PortletContent>
                <TextField
                    fullWidth
                    required
                    label="Nome de Praxe"
                    name="person"
                    onChange={handleChange}
                    type="text"
                    value={values.person}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    required
                    label="Nova Palavra-passe"
                    name="newPassword"
                    onChange={handleChange}
                    style={{ marginTop: "1rem" }}
                    type="password"
                    value={values.newPassword}
                    variant="outlined"
                    inputProps={{ minLength: 5 }}
                />
                </PortletContent>
                <PortletFooter className={classes.footer}>
                <Button
                    onClick={event => handleConfirmar()}
                    type={"button"}
                    color="primary"
                    disabled={
                        values.person.length === 0 ||
                        values.newPassword.length < 5
                    }
                    >
                    Alterar
                </Button>
                </PortletFooter>
            </form>
            </Portlet>
        </div>
    </DashboardLayout>
  );
};

Admin.propTypes = {
  className: PropTypes.string
};

export default withRouter(Admin);
