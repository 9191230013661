import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import {
  ProcurarPraxes,
  EditarPraxe,
  CriarPraxe,
  ListaPraxes,
  Detalhes,
  Presencas,
  AntiPraxe,
  Localizacoes
} from "./components";
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateDataReact, validateDataSQL } from '../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  list: {
    marginTop: theme.spacing(2)
  },
  editarButton: {
    marginRight: theme.spacing(1)
  }
}));

const Praxes = props => {
  const classes = useStyles();

  //const api = useContext(MyContext)

  const [values, setValues] = useState({
    openEditar: false,
    openCriar: false,
    selectedIndex: 0,
    selectedPraxeId: "1",
    filtro: ''
  });

  const [data, setData] = useState({ praxes: [] });
  const [bestas, setBestas] = useState({ bestas: [] });

  useEffect(() => {
    var id = props.match.params.id;

    const fetchPraxes = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/praxes`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())

      // Ordena a lista de praxes por ordem de data. Mais recentes aparecem
      // primeiro.
      resultado.sort(function(a, b){
        return new Date(b.data) - new Date(a.data)
      });

      // De acordo com o id da praxe, retorna o índice da lista
      // em que este se encontra (necessário para saber qual o elemento deverá
      // estar selecionado)
      const index = resultado.findIndex(function(item, i){
        return item.id == id
      })

      setValues({
        ...values,
        selectedIndex: index,
        selectedPraxeId: id
      });

      setData({
        ...data,
        praxes: resultado,
      });
    };

    const fetchBestas = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/bestas`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())

      // Ordenar a lista de bestas por ordem de disquete
      resultado.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      // Apenas bestas que se encontram na praxe
      const newResultado = resultado.filter(besta => besta.naPraxe == 1)

      setBestas({
        ...bestas,
        bestas: newResultado,
      });
    }

    fetchPraxes();
    fetchBestas();
  }, [values.update]);

  const handleSelect = (selectedIndex, selectedPraxeId) => {
    setValues({
      ...values,
      selectedIndex,
      selectedPraxeId
    });
    props.history.push("/praxes/" + selectedPraxeId);
  };

  const handleOpenEditar = () => {
    setValues({
      ...values,
      openEditar: true
    });
  };

  const handleSelectFiltro = event => {
    setValues({
      ...values,
      filtro: event.target.value
    })
  }

  const handleOpenCriar = () => {
    setValues({
      ...values,
      openCriar: true
    });
  };

  const handleCloseCriar = async (confirmar, praxe, checkedManha, checkedAlmoco, checkedJantar) => {
    // Os atributos do engenheiro a adicionar são definidos no componente
    // Criar Praxe. Este passa-os como argumento no objeto "praxe",
    // juntamente com a flag "confirmar", que indica se a praxe deve ser
    // inserido na base de dados ou não
    const criarPraxe = async () => {
      const body = {
        'data': validateDataSQL(praxe.data),
        'tipo': praxe.tipo,
        'nome': praxe.nome,
        'planeamento': praxe.planeamento,
        'comentarios': praxe.comentarios,
        'report': praxe.report
      }

      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/praxes`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
        .then(data => {
          const body = {
            'presencasManha': checkedManha,
            'presencasAlmoco': checkedAlmoco,
            'presencasJantar': checkedJantar,
            'idPraxe': data.id
          }

          fetch(`${API_URL}/v1/presencaspraxe`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
            .then(response => {
              if(!response.ok) {
                props.history.push('/iniciar-sessao')
              }
              return response.json()
            })
        })
    }

    if(confirmar) {
      criarPraxe();
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Criar Praxe
    setValues({
      ...values,
      update: !values.update,
      openCriar: false,
    })
  };

  const handleCloseEditar = async (confirmar, praxe, checkedManha, checkedAlmoco, checkedJantar) => {
    // Os atributos da besta a editar são definidos no componente
    // Editar Praxe. Este passa-os como argumento no objeto "praxe",
    // juntamente com a flag "confirmar", que indica se a praxe deve ser
    // editada na base de dados ou não.
    const editarPraxe = async () => {
      const body = {
        'data': validateDataSQL(praxe.data),
        'tipo': praxe.tipo,
        'nome': praxe.nome,
        'planeamento': praxe.planeamento,
        'comentarios': praxe.comentarios,
        'report': praxe.report
      }
      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/praxes/${values.selectedPraxeId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
        .then(data => {
          const body = {
            'presencasManha': checkedManha,
            'presencasAlmoco': checkedAlmoco,
            'presencasJantar': checkedJantar,
            'idPraxe': data.id
          }

          fetch(`${API_URL}/v1/presencaspraxe`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
            .then(response => {
              if(!response.ok) {
                props.history.push('/iniciar-sessao')
              }
              return response.json()
            })
        })
    }

    if(confirmar) {
      editarPraxe();
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Editar Praxe
    setValues({
      ...values,
      update: !values.update,
      openEditar: false,
    })
  }

  const loading = data.praxes.length === 0;
  return (
    <DashboardLayout title="Diários de Praxe">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={3} sm={4} xl={3} xs={5}>
            <ProcurarPraxes
              filtro={values.filtro}
              onHandleSelectFiltro={handleSelectFiltro}
            />
          </Grid>
          { loading
            ? null
            : <Grid
                item
                lg={9}
                sm={8}
                xl={9}
                xs={7}
                align="right"
                container
                justify="flex-end"
              >
                <EditarPraxe
                  openEditar={values.openEditar}
                  onHandleOpenEditar={handleOpenEditar}
                  onHandleCloseEditar={handleCloseEditar}
                  selectedPraxeId={values.selectedPraxeId}
                  praxes={data.praxes}
                  bestas={bestas.bestas}
                />
                <CriarPraxe
                  openCriar={values.openCriar}
                  onHandleOpenCriar={handleOpenCriar}
                  onHandleCloseCriar={handleCloseCriar}
                  bestas={bestas.bestas}
                />
              </Grid>
          }
        </Grid>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            { loading
              ? <CircularProgress />
              : <ListaPraxes
                  onHandleSelect={handleSelect}
                  selectedIndex={values.selectedIndex}
                  praxes={data.praxes}
                  filtro={values.filtro}
                />
            }
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={12}>
            { loading
              ? <CircularProgress />
              : <Detalhes
                  onHandleSelect={handleSelect}
                  selectedPraxeId={values.selectedPraxeId}
                  praxes={data.praxes}
                />
            }
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Presencas
                  className={classes.item}
                  selectedPraxeId={values.selectedPraxeId}
                />
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <AntiPraxe
                  className={classes.item}
                  selectedPraxeId={values.selectedPraxeId}
                />
              </Grid>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Localizacoes
                  className={classes.item}
                  selectedPraxeId={values.selectedPraxeId}
                />
              </Grid>
            </Grid>
          </Grid>
      </div>
    </DashboardLayout>
  );
};

export default withRouter(Praxes);
