import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { checkNullReturnBlank , validateDataReact} from '../../../../helpers/helperFunctions'

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Divider,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemSecondaryAction,
  Checkbox,
  ListItemAvatar
} from "@material-ui/core";
import { Portlet } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  margin: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1)
  },
  avatar: {
    marginBottom: theme.spacing(2),
    height: "125px",
    width: "125px",
    flexShrink: 0,
    flexGrow: 0
  },
  presencas: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    overflow: "auto"
  },
  headerPresencas: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  editarButton: {
    marginRight: theme.spacing(1)
  }
}));

const DadosPraxe = props => {
  const {
    onHandleSelect,
    onHandleDataPraxe,
    ...values
  } = props;

  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          select
          autoFocus
          label="Tipo"
          name="tipo"
          value={values.tipo}
          onChange={onHandleSelect}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
        >
          <MenuItem value="Normal">Normal</MenuItem>
          <MenuItem value="Especial">Especial</MenuItem>
        </TextField>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Nome"
          margin="dense"
          name="nome"
          onChange={onHandleSelect}
          defaultValue={values.nome}
          disabled={values.tipo === "Normal" ? true : false}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin="dense"
            inputVariant="outlined"
            label="Data"
            name="data"
            value={values.data}
            onChange={onHandleDataPraxe}
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={12} xs={12}>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            fullWidth
            multiline
            label="Planeamento"
            name="planeamento"
            className={classes.textField}
            value={values.planeamento}
            onChange={onHandleSelect}
            margin="dense"
            variant="outlined"
          />
        </form>
      </Grid>
    </Grid>
  );
};

const Presencas = props => {
  const {
    bestas,
    checkedManha,
    checkedAlmoco,
    checkedJantar,
    onHandleToggleManha,
    onHandleToggleAlmoco,
    onHandleToggleJantar
   } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {/* Presenças de Manhã */}

      <Grid item md={6} sm={6} xs={12}>
        <Portlet>
          <Typography
            variant="h6"
            align="center"
            className={classes.headerPresencas}
          >
            Manhã
          </Typography>
          <List dense className={classes.presencas}>
            {bestas.map((besta, i) => {
              return (
                <ListItem
                  key={besta.id}
                  button
                  onClick={onHandleToggleManha(besta.id)}
                >
                {/*
                  <ListItemAvatar>
                    <Avatar src={"http://www.tropadei.ml/" + besta.foto} />
                  </ListItemAvatar>
                */}
                  <Typography className={classes.text} variant="h6">
                    {besta.numDisquete} {besta.nomePraxe}
                  </Typography>
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={onHandleToggleManha(besta.id)}
                      checked={checkedManha.indexOf(besta.id) !== -1}
                      inputProps={{ "aria-labelledby": besta.id }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Portlet>
      </Grid>

      {/* Presenças ao Almoço */}

      <Grid item md={6} sm={6} xs={12}>
        <Portlet>
          <Typography
            variant="h6"
            align="center"
            className={classes.headerPresencas}
          >
            Almoço
          </Typography>
          <List dense className={classes.presencas}>
            {bestas.map((besta, i) => {
              return (
                <ListItem
                  key={besta.id}
                  button
                  onClick={onHandleToggleAlmoco(besta.id)}
                >
                {/*
                  <ListItemAvatar>
                    <Avatar src={"http://www.tropadei.ml/" + besta.foto} />
                  </ListItemAvatar>
                */}
                  <Typography className={classes.text} variant="h6">
                    {besta.numDisquete} {besta.nomePraxe}
                  </Typography>
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={onHandleToggleAlmoco(besta.id)}
                      checked={checkedAlmoco.indexOf(besta.id) !== -1}
                      inputProps={{ "aria-labelledby": besta.id }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Portlet>
      </Grid>

      {/* Presenças ao Jantar */}

      <Grid item md={6} sm={6} xs={12}>
        <Portlet>
          <Typography
            variant="h6"
            align="center"
            className={classes.headerPresencas}
          >
            Jantar
          </Typography>
          <List dense className={classes.presencas}>
            {bestas.map((besta, i) => {
              return (
                <ListItem
                  key={besta.id}
                  button
                  onClick={onHandleToggleJantar(besta.id)}
                >
                {/*
                  <ListItemAvatar>
                    <Avatar src={"http://www.tropadei.ml/" + besta.foto} />
                  </ListItemAvatar>
                */}
                  <Typography className={classes.text} variant="h6">
                    {besta.numDisquete} {besta.nomePraxe}
                  </Typography>
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={onHandleToggleJantar(besta.id)}
                      checked={checkedJantar.indexOf(besta.id) !== -1}
                      inputProps={{ "aria-labelledby": besta.id }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Portlet>
      </Grid>
    </Grid>
  );
};

const ComentariosDiario = props => {
  const {
    onHandleSelect,
    ...values
  } = props;

  const classes = useStyles();
  console.log(values)
  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            fullWidth
            multiline
            label="Comentários"
            name="comentarios"
            className={classes.textField}
            value={values.comentarios}
            onChange={onHandleSelect}
            margin="dense"
            variant="outlined"
          />
        </form>
      </Grid>
      <Grid item md={12} xs={12}>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            fullWidth
            multiline
            label="Diário de Praxe"
            name="report"
            className={classes.textField}
            value={values.report}
            onChange={onHandleSelect}
            margin="dense"
            variant="outlined"
          />
        </form>
      </Grid>
    </Grid>
  );
};

const EditarPraxe = props => {
  const { openEditar, praxes, bestas, selectedPraxeId, onHandleOpenEditar, onHandleCloseEditar } = props;

  const classes = useStyles();

  const praxe = praxes.find(praxe => praxe.id == selectedPraxeId);

  const [values, setValues] = useState(0);

  const [checkedManha, setCheckedManha] = React.useState([0]);
  const [checkedAlmoco, setCheckedAlmoco] = React.useState([0]);
  const [checkedJantar, setCheckedJantar] = React.useState([0]);

  useEffect(() => {
    const fetchPresencas = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/praxes/${selectedPraxeId}/presencas`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      const manha = [];
      const almoco = [];
      const jantar = [];

      for (var i in resultado.manha) {
        manha.push(resultado.manha[i].id)
      }

      for (i in resultado.almoco) {
        almoco.push(resultado.almoco[i].id)
      }

      for (i in resultado.jantar) {
        jantar.push(resultado.jantar[i].id)
      }

      setCheckedManha(manha)
      setCheckedAlmoco(almoco)
      setCheckedJantar(jantar)
    }

    fetchPresencas();

    setValues({
      ...values,
      nome: checkNullReturnBlank(praxe.nome),
      data: validateDataReact(praxe.data),
      tipo: checkNullReturnBlank(praxe.tipo),
      comentarios: checkNullReturnBlank(praxe.comentarios),
      planeamento: checkNullReturnBlank(praxe.planeamento),
      report: checkNullReturnBlank(praxe.report)
    })
  }, [openEditar])

  const handleSelect = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDataPraxe = data => {
    setValues({
      ...values,
      data
    })
  }

  const handleToggleManha = value => () => {
    const currentIndex = checkedManha.indexOf(value);
    const newCheckedManha = [...checkedManha];

    if (currentIndex === -1) {
      newCheckedManha.push(value);
    } else {
      newCheckedManha.splice(currentIndex, 1);
    }

    setCheckedManha(newCheckedManha);
  };

  const handleToggleAlmoco = value => () => {
    const currentIndex = checkedAlmoco.indexOf(value);
    const newCheckedAlmoco = [...checkedAlmoco];

    if (currentIndex === -1) {
      newCheckedAlmoco.push(value);
    } else {
      newCheckedAlmoco.splice(currentIndex, 1);
    }

    setCheckedAlmoco(newCheckedAlmoco);
  };

  const handleToggleJantar = value => () => {
    const currentIndex = checkedJantar.indexOf(value);
    const newCheckedJantar = [...checkedJantar];

    if (currentIndex === -1) {
      newCheckedJantar.push(value);
    } else {
      newCheckedJantar.splice(currentIndex, 1);
    }

    setCheckedJantar(newCheckedJantar);
  };

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={onHandleOpenEditar}
        className={classes.editarButton}
      >
        Editar Praxe
      </Button>
      <Dialog
        open={openEditar}
        onClose={event => onHandleCloseEditar(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Editar Praxe</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Dados da Praxe e Planeamento
          </DialogContentText>
          <DadosPraxe
            onHandleSelect={handleSelect}
            onHandleDataPraxe={handleDataPraxe}
            {...values} />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Presenças
          </DialogContentText>
          <Presencas
            checkedManha={checkedManha}
            checkedAlmoco={checkedAlmoco}
            checkedJantar={checkedJantar}
            onHandleToggleManha={handleToggleManha}
            onHandleToggleAlmoco={handleToggleAlmoco}
            onHandleToggleJantar={handleToggleJantar}
            bestas={bestas}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Comentários e Diário de Praxe
          </DialogContentText>
          <ComentariosDiario
            selectedPraxeId={selectedPraxeId}
            praxes={praxes}
            onHandleSelect={handleSelect}
            {...values}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={event => onHandleCloseEditar(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={event => onHandleCloseEditar(true, values, checkedManha, checkedAlmoco, checkedJantar)} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(EditarPraxe);
