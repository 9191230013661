import React from "react";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Typography
} from "@material-ui/core";
import { Portlet, PortletContent } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 470,
    overflow: "auto"
  },
  tableRow: {
    height: "64px"
  },
  scroll: {
    maxHeight: "100%",
    overflow: "auto"
  },
  tableCell: {
    whiteSpace: "nowrap"
  },
  tableCellInner: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px"
  },
  nameText: {
    display: "inline-block",
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: "pointer"
  }
}));

const ListaReunioes = props => {
  const { className, selectedIndex, filtro, onHandleSelect, praxes } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const loweredCasedFiltro = filtro.toLowerCase()

  const filteredPraxes = praxes.filter(item => {
      return Object.keys(item).some(key =>
        typeof item[key] === "string" && item[key].toLowerCase().includes(loweredCasedFiltro)
      );
    });

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <List className={classes.root}>
            {filteredPraxes.map((praxe, i) => (
              <ListItem
                key={praxe.id}
                button
                selected={selectedIndex === i}
                onClick={event => onHandleSelect(i, praxe.id)}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <Moment format="ddd">{praxe.data}</Moment>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={ praxe.tipo == "Especial"
                    ? <Typography className={classes.nameText} variant="h5">
                        {praxe.nome}
                    </Typography>
                    : <Typography className={classes.nameText} variant="h5">
                        <Moment format="D [de] MMMM">{praxe.data}</Moment>
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
};

export default ListaReunioes;
