import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { EditarComentarios } from "./components";
import { Button, Typography } from "@material-ui/core";
import { ArrowRightOutlined } from "@material-ui/icons";
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from "../../../../components";
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateDataReact } from '../../../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {},
  portletContent: {
    paddingTop: "0"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  product: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    "&:not(:first-of-type)": {
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  productImageWrapper: {
    borderRadius: "5px",
    overflow: "hidden",
    height: "64px",
    width: "64px"
  },
  productImage: {
    width: "100%",
    height: "auto"
  },
  productDetails: {
    marginLeft: theme.spacing(2),
    flexGrow: 1
  },
  productTitle: {
    whiteSpace: "pre-line"
  },
  productTimestamp: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  spacer: {
    flexGrow: 1
  },
  row: {
    display: "flex",
    alignItems: "center"
  }
}));

const Comentarios = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const [values, setValues] = useState({
    open: false,
    update: false,
    comentarios: '',
    selectedComentarios: '',
    timestamp: new Date(),
    idPraxe: ''
  });

  useEffect(() => {
    const fetchComentarios = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/praxes/ultimaPraxe`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        comentarios: resultado.comentarios,
        selectedComentarios: resultado.comentarios,
        timestamp: validateDataReact(resultado.updated_at),
        idPraxe: resultado.id
      });
    }
    fetchComentarios();
  }, [values.update])

  const handleOpen = () => {
    setValues({
      ...values,
      open: true
    });
  };

  const handleSelect = event => {
    setValues({
      ...values,
      selectedComentarios: event.target.value
    });
  };

  const handleVerPraxe = id => {
    props.history.push("/praxes/" + id);
  };

  const handleClose = async confirmar => {
    const editarPraxe = async () => {
      const body = {
        'comentarios': values.selectedComentarios
      }
      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/praxes/${values.idPraxe}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
    }

    if(confirmar) {
      editarPraxe();
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Editar Praxe
    setValues({
      ...values,
      update: !values.update,
      open: false,
    })
  }

  const loading = values.comentarios === ''

  return (
    loading ? <CircularProgress />
    : <Portlet className={rootClassName}>
      <PortletHeader noDivider>
        <PortletLabel title="Comentários" />
        <Typography className={classes.productTitle} variant="body1">
          <Moment fromNow>{values.timestamp}</Moment>
        </Typography>
      </PortletHeader>
      { loading ? null :
      <PortletContent className={classes.portletContent}>
        <Typography className={classes.productTitle} variant="body1">
          {values.comentarios}
        </Typography>
      </PortletContent> }
      { loading ? null :
      <PortletFooter>
        <div className={classes.row}>
          <EditarComentarios
            className={classes.item}
            open={values.open}
            selectedComentarios={values.selectedComentarios}
            onHandleSelect={handleSelect}
            onHandleOpen={handleOpen}
            onHandleClose={handleClose}
          />
          <span className={classes.spacer} />
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={event => handleVerPraxe(values.idPraxe)}
          >
            Ver diário de praxe <ArrowRightOutlined />
          </Button>
        </div>
      </PortletFooter>}
    </Portlet>
  );
};

export default withRouter(Comentarios);
