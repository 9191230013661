import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from "@material-ui/core";
import { ExpandMoreOutlined as ExpandirIcon } from "@material-ui/icons";
import { Expansion } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  dropdownButton: {
    marginRight: -theme.spacing(2)
  },
  chartWrapper: {
    height: "400px",
    position: "relative"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const EstatisticasBestas = props => {
  const { selectedBestaId } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    geral: -1,
    manha: -1,
    almoco: -1,
    jantar: -1,
  });

  useEffect(() => {
    const fetchPresencas = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/presencasPraxe/${selectedBestaId}`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        geral: resultado.geral,
        manha: resultado.manha,
        almoco: resultado.almoco,
        jantar: resultado.jantar
      });
    }
    fetchPresencas();
  }, [selectedBestaId])

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Estatísticas</Typography>
          <Typography className={classes.secondaryHeading}>
            {'Geral: '}{Math.round(values.geral)}{'% '}
            {'(Manhã: '}{Math.round(values.manha)}{'% '}
            {'Almoço: '}{Math.round(values.almoco)}{'% '}
            {'Jantar: '}{Math.round(values.jantar)}{'%)'}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex sit amet blandit leo lobortis eget.
          </Typography>
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};

export default EstatisticasBestas;
