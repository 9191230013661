import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Avatar,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Tooltip
} from "@material-ui/core";
import { ExpandMoreOutlined as ExpandirIcon } from "@material-ui/icons";
import { Expansion } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  dropdownButton: {
    marginRight: -theme.spacing(2)
  },
  chartWrapper: {
    height: "400px",
    position: "relative"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  avatar: {
    margin: 5,
    width: 60,
    height: 60
  }
}));

const AntiPraxe = props => {
  const { selectedPraxeId } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    antiPraxe: []
  });

  useEffect(() => {
    const fetchAntiPraxe = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/praxes/${selectedPraxeId}/antipraxe`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        antiPraxe: resultado
      });
    }

    fetchAntiPraxe();
  }, [selectedPraxeId])

  const loading = values.antiPraxe.length === 0
  const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Anti-Praxe</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {loading ? null :
          <Grid container justify="row">
            {values.antiPraxe.map((besta, i) => (
              <Tooltip title={besta.numDisquete}>
                <Avatar
                  src={IMAGES_URL + besta.foto}
                  className={classes.avatar}
                  component={RouterLink}
                  to={"/bestas/" + besta.id}
                />
              </Tooltip>
            ))}
          </Grid>}
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};

export default withRouter(AntiPraxe);
