import React from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { SocialIcon } from "react-social-icons";
import { Portlet, PortletContent, PortletFooter } from "../../../../components";
import { Avatar, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: "flex"
  },
  avatar: {
    marginLeft: "auto",
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0
  },
  text: {
    color: theme.palette.text.secondary
  },
  progress: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  first: {
    marginTop: theme.spacing(1)
  }
}));

const Detalhes = props => {
  const { className, user, onHandleSelectFoto, onHandleRemoveFile, ...rest } = props;

  const classes = useStyles();

  return (
    <Portlet {...rest} className={clsx(classes.root, className)}>
      <PortletContent>
        <div className={classes.details}>
          <div>
            <div className={classes.header}>
              <Typography variant="h2" style={{ marginRight: 10 }}>
                {user.numDisquete} {user.nomePraxe}
              </Typography>
              <Typography
                className={classes.text}
                variant="h4"
                style={{ marginRight: 10 }}
              >
                {user.grauHierarquico}
              </Typography>
              <SocialIcon url={"https://facebook.com/" + user.facebookID} style={{ height: 30, width: 30 }} />
            </div>
            <Typography
              className={classes.first}
              color="textSecondary"
              variant="body1"
            >
              {user.primeiroNome} {user.ultimoNome}
            </Typography>
            <Typography
              className={classes.locationText}
              variant="body1"
              color="textSecondary"
            >
              <Moment diff={user.dataNascimento} unit="years">
                {new Date()}
              </Moment>{" "}
              anos (<Moment format="D [de] MMMM">{user.dataNascimento}</Moment>)
            </Typography>
            <Typography
              className={classes.progress}
              color="textSecondary"
              variant="body1"
            >
              {user.rua}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {user.freguesia}, {user.cidade}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {user.telemovel}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {user.email}
            </Typography>
            <Typography
              className={classes.progress}
              color="textSecondary"
              variant="body1"
            >
              {user.numAluno}
            </Typography>
          </div>
          <Avatar className={classes.avatar} src={user.fotoPreview} />
        </div>
      </PortletContent>
      {/*<PortletFooter>
        <input
          accept="image/*"
          type="file"
          id="foto-input"
          name="foto"
          style={{ display: 'none' }}
          onChange={onHandleSelectFoto}
        />
        <label htmlFor="foto-input">
          <Button
            color="primary"
            size="small"
            variant="text"
            component="span"
          >
            Adicionar Foto
          </Button>
        </label>
        <Button
          size="small"
          variant="text"
          component="span"
          onClick={(e) => {
              onHandleRemoveFile(e, 'foto');
              onHandleRemoveFile(e, 'fotoPreview');
            }
          }
        >
          Remover Foto
        </Button>
      </PortletFooter>*/}
    </Portlet>
  );
};

Detalhes.propTypes = {
  className: PropTypes.string
};

export default Detalhes;
