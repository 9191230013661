import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  DashboardOutlined as DashboardIcon,
  FaceOutlined as BestasIcon,
  PeopleOutlined as EngenheirosIcon,
  EqualizerOutlined as EstatisticasIcon,
  EventNoteOutlined as CalendarioIcon,
  AssignmentOutlined as DiariosPraxeIcon,
  QuestionAnswerOutlined as ReunioesIcon,
  LocationOnOutlined as LocaisIcon,
  ListOutlined as TopicosIcon,
  AccountBoxOutlined as PerfilIcon,
  SettingsOutlined as DefinicoesIcon
} from '@material-ui/icons';
import styles from './styles.jsx';

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

class Sidebar extends Component {
  checkPathBestas = (match, location) => {
    return (location.pathname.startsWith('/besta')) ? true : false
  }

  checkPathEngenheiros = (match, location) => {
    return (location.pathname.startsWith('/engenheiros')) ? true : false
  }

  checkPathPraxes = (match, location) => {
    return (location.pathname.startsWith('/praxes')) ? true : false
  }

  checkPathReunioes = (match, location) => {
    return (location.pathname.startsWith('/reunioes')) ? true : false
  }

  checkPathTopicos = (match, location) => {
    return (location.pathname.startsWith('/topicos')) ? true : false
  }

  checkPathLocais = (match, location) => {
    return (location.pathname.startsWith('/locais')) ? true : false
  }

  constructor(props) {
    super(props);
    this.state = {
      foto: JSON.parse(localStorage.getItem("user")).foto,
      nomePraxe: JSON.parse(localStorage.getItem("user")).nomePraxe,
      grauHierarquico: JSON.parse(localStorage.getItem("user")).grauHierarquico,
    }
  }

  render() {
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);
    const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link
            className={classes.logoLink}
            to="/"
          >
            <img
              alt="Curso logo"
              className={classes.logoImage}
              src="/images/logos/curso.png"
            />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
          <div className={classes.profile}>
            <Link to="/perfil">
              <Avatar
                alt={this.state.foto}
                className={classes.avatar}
                src={IMAGES_URL + this.state.foto}
              />
            </Link>
            <Typography
              className={classes.nameText}
              variant="h6"
            >
              {this.state.nomePraxe}
            </Typography>
            <Typography
              className={classes.bioText}
              variant="caption"
            >
              {this.state.grauHierarquico}
            </Typography>
          </div>
        <Divider className={classes.profileDivider} />
        <List
          component="div"
          disablePadding
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to="/dashboard"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            exact className={classes.listItem}
            component={ForwardNavLink}
            isActive={this.checkPathBestas}
            to="/bestas"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <BestasIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Bestas"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            isActive={this.checkPathEngenheiros}
            to="/engenheiros"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <EngenheirosIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Engenheiros"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to="/estatisticas"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <EstatisticasIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Estatísticas"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to="/calendario"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CalendarioIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Calendário"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            isActive={this.checkPathPraxes}
            to="/praxes"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DiariosPraxeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Diários de Praxe"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            isActive={this.checkPathReunioes}
            to="/dashboard"
            disabled
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReunioesIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Reuniões"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            isActive={this.checkPathTopicos}
            to="/dashboard"
            disabled
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TopicosIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Tópicos"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            isActive={this.checkPathLocais}
            to="/locais"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LocaisIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Locais"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to="/perfil"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PerfilIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Perfil"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to="/definicoes"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DefinicoesIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Definições"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to="/admin"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DefinicoesIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Admin"
            />
          </ListItem>
        </List>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
