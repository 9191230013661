import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Divider,
  Avatar
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  menu: {
    width: 200
  },
  margin: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1)
  },
  avatar: {
    marginBottom: theme.spacing(2),
    height: "125px",
    width: "125px",
    flexShrink: 0,
    flexGrow: 0
  }
}));

const DadosPessoais = props => {
  const {
    onHandleSelect,
    onHandleDataNascimento,
    ...values
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          autoFocus
          fullWidth
          required
          type="number"
          label="Número de Disquete"
          margin="dense"
          name="numDisquete"
          onChange={onHandleSelect}
          defaultValue={values.numDisquete}
          variant="outlined"
          error={values.numDisquete.length === 0 ? true : false}
          helperText={values.numDisquete.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Nome de Praxe"
          margin="dense"
          name="nomePraxe"
          onChange={onHandleSelect}
          defaultValue={values.nomePraxe}
          variant="outlined"
          error={values.nomePraxe.length === 0 ? true : false}
          helperText={values.nomePraxe.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Primeiro Nome"
          margin="dense"
          name="primeiroNome"
          onChange={onHandleSelect}
          value={values.primeiroNome}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Último Nome"
          margin="dense"
          name="ultimoNome"
          onChange={onHandleSelect}
          value={values.ultimoNome}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Outros Nomes"
          margin="dense"
          name="outrosNomes"
          onChange={onHandleSelect}
          defaultValue={values.outrosNomes}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin="dense"
            inputVariant="outlined"
            label="Data de Nascimento"
            name="dataNascimento"
            value={values.dataNascimento}
            onChange={onHandleDataNascimento}
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

const MoradaContactos = props => {
  const {
    onHandleSelect,
    ...values
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Cidade"
          margin="dense"
          name="cidade"
          onChange={onHandleSelect}
          defaultValue={values.cidade}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Freguesia"
          margin="dense"
          name="freguesia"
          onChange={onHandleSelect}
          defaultValue={values.freguesia}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Rua"
          margin="dense"
          name="rua"
          onChange={onHandleSelect}
          defaultValue={values.rua}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Email"
          margin="dense"
          name="email"
          onChange={onHandleSelect}
          defaultValue={values.email}
          variant="outlined"
          error={values.email.length === 0 ? true : false}
          helperText={values.email.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Telemóvel"
          margin="dense"
          name="telemovel"
          onChange={onHandleSelect}
          defaultValue={values.telemovel}
          variant="outlined"
          error={values.telemovel.length === 0 ? true : false}
          helperText={values.telemovel.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Facebook ID"
          margin="dense"
          name="facebookID"
          onChange={onHandleSelect}
          defaultValue={values.facebookID}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

const UniversidadePraxe = props => {
  const {
    onHandleSelect,
    ...values
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Número de Aluno"
          margin="dense"
          name="numAluno"
          onChange={onHandleSelect}
          defaultValue={values.numAluno}
          variant="outlined"
          error={values.numAluno.length === 0 ? true : false}
          helperText={values.numAluno.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          select
          label="Grau Hierárquico"
          name="grauHierarquico"
          value={values.grauHierarquico}
          onChange={onHandleSelect}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
          error={values.grauHierarquico.length === 0 ? true : false}
          helperText={values.grauHierarquico.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        >
          <MenuItem value="Engenheiro">Engenheiro</MenuItem>
          <MenuItem value="Bispo">Bispo</MenuItem>
          <MenuItem value="Cardeal">Cardeal</MenuItem>
          <MenuItem value="Cardeal de Curso">Cardeal de Curso</MenuItem>
          <MenuItem value="Super-Engenheiro">Super-Engenheiro</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

const PalavraPasse = props => {
  const {
    onHandleSelect,
    ...values
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Palavra-passe"
          margin="dense"
          name="password"
          onChange={onHandleSelect}
          defaultValue={values.password}
          variant="outlined"
          type="password"
          error={values.password.length === 0 ? true : false}
          helperText={values.password.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
      <TextField
        fullWidth
        required
        label="Confirmar palavra-passe"
        margin="dense"
        name="confirmarPassword"
        onChange={onHandleSelect}
        defaultValue={values.confirmarPassword}
        variant="outlined"
        type="password"
        error={values.password !== values.confirmarPassword ? true : false}
        helperText={values.password !== values.confirmarPassword ? "Palavras-passe não correspondem" : ""}
      />
      </Grid>
    </Grid>
  );
};

const AdicionarEngenheiro = props => {
  const {
    openAdicionar,
    onHandleOpenAdicionar,
    onHandleCloseAdicionar
  } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    numDisquete: '',
    nomePraxe: '',
    primeiroNome: '',
    ultimoNome: '',
    outrosNomes: '',
    foto: '',
    fotoPreview: '',
    dataNascimento: new Date(),
    telemovel: '',
    email: '',
    cidade: '',
    freguesia: '',
    rua: '',
    facebookID: '',
    numAluno: '',
    grauHierarquico: '',
    password: '',
    confirmarPassword: '',
  });

  const handleSelect = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSelectFoto = event => {
    setValues({
      ...values,
      foto: event.target.files[0],
      fotoPreview: URL.createObjectURL(event.target.files[0])
    })
  }

  const handleRemoveFile = (e, propriedade) => {
    setValues({
      ...values,
      [propriedade]: ''
    })
  }

  const handleDataNascimento = dataNascimento => {
    setValues({
      ...values,
      dataNascimento
    });
  };

  const handleLimparCampos = () => {
    setValues({
      ...values,
      numDisquete: '',
      nomePraxe: '',
      primeiroNome: '',
      ultimoNome: '',
      outrosNomes: '',
      foto: '',
      fotoPreview: '',
      dataNascimento: new Date(),
      telemovel: '',
      email: '',
      cidade: '',
      freguesia: '',
      rua: '',
      facebookID: '',
      numAluno: '',
      grauHierarquico: '',
      password: '',
      confirmarPassword: '',
    })
  }

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="outlined"
        onClick={onHandleOpenAdicionar}
      >
        Adicionar Engenheiro
      </Button>
      <Dialog
        open={openAdicionar}
        onClose={event => {
            onHandleCloseAdicionar(false);
            handleLimparCampos();
          }
        }
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Adicionar Engenheiro</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} src={values.fotoPreview} />
          </div>
          <div className={classes.button}>
            <input
              accept="image/*"
              type="file"
              id="foto-input"
              name="foto"
              style={{ display: 'none' }}
              onChange={handleSelectFoto}
            />
            <label htmlFor="foto-input">
              <Button
                color="primary"
                size="small"
                variant="text"
                component="span"
              >
                Adicionar Foto
              </Button>
            </label>
            <Button
              size="small"
              variant="text"
              component="span"
              onClick={(e) => {
                  handleRemoveFile(e, 'foto');
                  handleRemoveFile(e, 'fotoPreview');
                }
              }
            >
              Remover Foto
            </Button>
          </div>
          <DialogContentText align="center" variant="h6">
            Dados Pessoais
          </DialogContentText>
          <DadosPessoais
            onHandleSelect={handleSelect}
            onHandleDataNascimento={handleDataNascimento}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Morada e Contactos
          </DialogContentText>
          <MoradaContactos
            onHandleSelect={handleSelect}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Universidade e Praxe
          </DialogContentText>
          <UniversidadePraxe
            onHandleSelect={handleSelect}
            {...values}
           />
           <DialogContentText
             align="center"
             variant="h6"
             className={classes.margin}
           >
             Palavra-passe
           </DialogContentText>
           <PalavraPasse
             onHandleSelect={handleSelect}
             {...values}
            />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={event => {
                onHandleCloseAdicionar(false);
                handleLimparCampos();
            }
          }
          color="primary">
            Cancelar
          </Button>
          <Button
            onClick={event => onHandleCloseAdicionar(true, values)}
            color="primary"
            disabled={(values.numDisquete.length === 0
              || values.nomePraxe.length === 0
              || values.grauHierarquico.length === 0
              || values.telemovel.length === 0
              || values.email.length === 0
              || values.numAluno.length === 0
              || values.password.length === 0)
              || values.password !== values.confirmarPassword ? true : false}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdicionarEngenheiro;
