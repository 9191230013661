import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import {
  Localizacao,
  PraxeEspecial,
  Reuniao,
  Aniversarios,
  JantarCurso,
  Comentarios
} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  item: {
    height: "100%"
  }
}));

const Dashboard = props => {
  const classes = useStyles();

  return (
    <DashboardLayout title="Dashboard">
      <div className={classes.root}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Localizacao />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Reuniao />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <PraxeEspecial />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <JantarCurso />
          </Grid>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <Aniversarios />
          </Grid>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <Comentarios />
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
