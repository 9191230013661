import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Avatar,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Tooltip
} from "@material-ui/core";
import { ExpandMoreOutlined as ExpandirIcon } from "@material-ui/icons";
import { Expansion } from "../../../../components";
import data from "../../../../data/bestas";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  dropdownButton: {
    marginRight: -theme.spacing(2)
  },
  chartWrapper: {
    height: "400px",
    position: "relative"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  avatar: {
    margin: 5,
    width: 60,
    height: 60
  }
}));

const Faltas = props => {
  const classes = useStyles();

  const [values] = useState({
    bestas: data
  });

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Faltas</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container justify="row">
            {values.bestas.map((besta, i) => (
              <Tooltip title={besta.numDisquete}>
                <Avatar
                  src={besta.foto}
                  className={classes.avatar}
                  component={RouterLink}
                  to={"/bestas/" + besta.id}
                />
              </Tooltip>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};
Faltas.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default Faltas;
