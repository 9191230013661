import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';
import moment from 'moment';
import 'moment/locale/pt';

const browserHistory = createBrowserHistory();

const App = () => {
  moment.locale('pt')

  return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes/>
        </Router>
      </ThemeProvider>
  );
}

export default App;
