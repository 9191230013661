export default [
  {
    id: "1",
    numDisquete: "12",
    nomePraxe: "Bolt",
    primeiroNome: "Armando",
    ultimoNome: "Santos",
    outrosNomes: "João Isaías",
    foto: "/images/avatars/avatar_bolt.jpg",
    dataNascimento: "1997-07-25T18:25:43.511Z",
    telemovel: "969 684 747",
    email: "armandoifsantos@gmail.com",
    cidade: "Braga",
    morada: {
      freguesia: "Ferreiros",
      rua: "Rua X"
    },
    facebookID: "bolt012",
    numAluno: "AXXXXX",
    grauHierarquico: "Bispo"
  },
  {
    id: "2",
    numDisquete: "13",
    nomePraxe: "BadCover",
    primeiroNome: "Maria",
    ultimoNome: "Valente",
    outrosNomes: "Elisa",
    foto: "/images/avatars/avatar_badC.jpg",
    dataNascimento: "1997-12-09T18:25:43.511Z",
    telemovel: "969 590 615",
    email: "mariejamby@gmail.com",
    cidade: "Viana do Castelo",
    morada: {
      freguesia: "Freguesia X",
      rua: "Rua X"
    },
    facebookID: "elisaa.valente",
    numAluno: "AXXXXX",
    grauHierarquico: "Bispo"
  }
];
