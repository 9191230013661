import moment from "moment"

// O React dá warnings quando se utilizam valores null como input para forms,
// etc. Esta função substitui valores null por string vazia
export const checkNullReturnBlank = item => {
  return item = item == null ? '' : item
}

// O SQL não aceita datas no formato que os componentes React geram. É
// necessário converter antes de serem enviadas para o Backend.
export const validateDataSQL = data => {
  if(data != null) {
    return moment(data).format("YYYY-MM-DD HH:mm:ss")
  }
  else return null
}

// O DatePicker não aceita datas NULL da base de dados como input. Nesse caso,
// retornamos a data atual
export const validateDataReact = data => {
  if(data == null) {
    return new Date()
  }
  else return data
}
