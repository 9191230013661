import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import {
  ProcurarBestas,
  ListaBestas,
  Perfil,
  EstatisticasBestas,
  PraxesEspeciais,
  Quotes,
  AdicionarBesta
} from "./components";
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateDataSQL } from '../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Bestas = props => {
  const classes = useStyles();

  const [values, setValues] = useState({
    selectedIndex: 0,
    selectedBestaId: 1,
    openAdicionar: false,
    openEditar: false,
    update: false,
    filtro: ''
  });

  const [data, setData] = useState({ bestas: [] });

  useEffect(() => {
    var id = props.match.params.id;

    const fetchBestas = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/bestas`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      // Ordenar a lista de bestas por ordem de disquete
      resultado.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      // Posteriormente, ordena a lista de bestas de acordo com o critério de
      // está na Praxe > não está na Praxe para as atuais bestas aparecerem
      // primeiro
      resultado.sort(function(a, b){
        return b.naPraxe - a.naPraxe
      });

      // De acordo com o id do engenheiro, retorna o índice da lista
      // em que este se encontra (necessário para saber qual o elemento deverá
      // estar selecionado)
      const index = resultado.findIndex(function(item, i){
        return item.id == id
      })

      setValues({
        ...values,
        selectedIndex: index,
        selectedBestaId: id,
      });

      setData({
        ...data,
        bestas: resultado
      });
    };
    fetchBestas();
  }, [values.update]);

  const handleSelect = (selectedIndex, selectedBestaId) => {
    setValues({
      ...values,
      selectedIndex,
      selectedBestaId,
    });
    props.history.push("/bestas/" + selectedBestaId);
  };

  const handleSelectFiltro = event => {
    setValues({
      ...values,
      filtro: event.target.value
    })
  }

  const handleOpenAdicionar = () => {
    setValues({
      ...values,
      openAdicionar: true
    });
  };

  const handleCloseAdicionar = async (confirmar, besta) => {
    if(confirmar) {
      // Os atributos da besta a adicionar são definidos no componente
      // Adicionar Besta. Este passa-os como argumento no objeto "besta",
      // juntamente com a flag "confirmar", que indica se a besta deve ser
      // inserida na base de dados ou não

      const formData = new FormData();
      
      formData.append('numDisquete', parseInt(besta.numDisquete))
      formData.append('nomePraxe', besta.nomePraxe)
      formData.append('diaDisquete', validateDataSQL(besta.diaDisquete))
      formData.append('primeiroNome', besta.primeiroNome)
      formData.append('ultimoNome', besta.ultimoNome)
      formData.append('outrosNomes', besta.outrosNomes)
      formData.append('dataNascimento', validateDataSQL(besta.dataNascimento))
      formData.append('telemovel', besta.telemovel)
      formData.append('telemovelEmergencia', besta.telemovelEmergencia)
      formData.append('naPraxe', '1')
      formData.append('assinouFolha', '0')
      formData.append('email', besta.email)
      formData.append('cidade', besta.cidade)
      formData.append('freguesia', besta.freguesia)
      formData.append('rua', besta.rua)
      formData.append('facebookID', besta.facebookID)
      formData.append('numAluno', besta.numAluno)
      formData.append('horario', besta.horario)

      if(besta.foto !== null && besta.foto !== undefined)
        formData.append("foto", besta.foto);
      if(besta.questionario !== null && besta.questionario !== undefined)
        formData.append("questionario", besta.questionario);
      if(besta.autorizacaoPais !== null && besta.autorizacaoPais !== undefined)
        formData.append("autorizacaoPais", besta.autorizacaoPais);

      for( var value of formData.values()){
        console.log(value)
      }

      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/bestas`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
        },
        body: formData
      })
      .then(response => {
        console.log(response)
        if(!response.ok) {
          props.history.push('/iniciar-sessao')
        }
        return response.json()
      })
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardado
    // no estado da componente e fechamos a página de Adicionar Besta
    setValues({
      ...values,
      update: !values.update,
      openAdicionar: false,
    })
  };

  const handleOpenEditar = () => {
    setValues({
      ...values,
      openEditar: true
    });
  };


  const handleCloseEditar = async (confirmar, besta) => {
    if(confirmar) {
      // Os atributos da besta a editar são definidos no componente
      // Editar Perfil. Este passa-os como argumento no objeto "besta",
      // juntamente com a flag "confirmar", que indica se a besta deve ser
      // editada na base de dados ou não.
      
      const formData = new FormData();

      formData.append('id', parseInt(values.selectedBestaId))
      formData.append('numDisquete', besta.numDisquete)
      formData.append('nomePraxe', besta.nomePraxe)
      formData.append('diaDisquete', validateDataSQL(besta.diaDisquete))
      formData.append('primeiroNome', besta.primeiroNome)
      formData.append('ultimoNome', besta.ultimoNome)
      formData.append('outrosNomes', besta.outrosNomes)
      formData.append('dataNascimento', validateDataSQL(besta.dataNascimento))
      formData.append('telemovel', besta.telemovel)
      formData.append('telemovelEmergencia', besta.telemovelEmergencia)
      formData.append('email', besta.email)
      formData.append('cidade', besta.cidade)
      formData.append('freguesia', besta.freguesia)
      formData.append('rua', besta.rua)
      formData.append('facebookID', besta.facebookID)
      formData.append('numAluno', besta.numAluno)
      formData.append('horario', besta.horario)
      formData.append('naPraxe', besta.naPraxe)
      formData.append('dataAP', validateDataSQL(besta.dataAntiPraxe))
      formData.append('assinouFolha', besta.assinouFolha)

      if(besta.foto !== null && besta.foto !== undefined && !(typeof besta.foto === "string"))
        formData.append("foto", besta.foto);
      if(besta.questionario !== null && besta.questionario !== undefined && !(typeof besta.questionario === "string"))
        formData.append("questionario", besta.questionario);
      if(besta.autorizacaoPais !== null && besta.autorizacaoPais !== undefined && !(typeof besta.autorizacaoPais === "string"))
        formData.append("autorizacaoPais", besta.autorizacaoPais);

      if(besta.naPraxe) {
        besta.assinouFolha = false;
        besta.dataAP = null;
        besta.folha = null;
      } else {
          if(besta.folha !== null && besta.folha !== undefined && !(typeof besta.folha === "string"))
              formData.append("folha", besta.folha);
      }

      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/bestas/${values.selectedBestaId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
        },
        body: formData
      })
      .then(response => {
        if(!response.ok) {
          props.history.push('/iniciar-sessao')
        }
        return response.json()
      })
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Editar Perfil
    setValues({
      ...values,
      update: !values.update,
      openEditar: false,
    })
  }

  const loading = data.bestas.length === 0;

  return (
    <DashboardLayout title="Bestas">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={3} sm={4} xl={3} xs={9}>
            <ProcurarBestas
              filtro={values.filtro}
              onHandleSelectFiltro={handleSelectFiltro}
            />
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={3} align="right">
            {loading
              ? <CircularProgress />
              : <AdicionarBesta
                  openAdicionar={values.openAdicionar}
                  onHandleOpenAdicionar={handleOpenAdicionar}
                  onHandleCloseAdicionar={handleCloseAdicionar}
                />
              }
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            {loading
              ? <CircularProgress />
              : <ListaBestas
                  onHandleSelect={handleSelect}
                  selectedIndex={values.selectedIndex}
                  filtro={values.filtro}
                  bestas={data.bestas}
                />
              }
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={12}>
            {loading
              ? <CircularProgress />
              : <Perfil
                  openEditar={values.openEditar}
                  onHandleOpenEditar={handleOpenEditar}
                  onHandleCloseEditar={handleCloseEditar}
                  selectedBestaId={values.selectedBestaId}
                  bestas={data.bestas}
                />
              }
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <EstatisticasBestas
                className={classes.item}
                selectedBestaId={values.selectedBestaId}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <PraxesEspeciais
                className={classes.item}
                selectedBestaId={values.selectedBestaId}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              {/*<Quotes className={classes.item} />*/}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default withRouter(Bestas);
