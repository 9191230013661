import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Portlet, PortletContent } from "../../../../components";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

import { DeleteOutlined as ApagarIcon } from "@material-ui/icons";
import { validateDataReact } from '../../../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tableRow: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0
    }
  },
  inner: {
    //minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const Historico = props => {
  const { className, selectedLocalId, locais, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    historico: []
  })

  useEffect(() => {
    const fetchData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/locaisPraxe/${selectedLocalId}`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
      setValues({
        ...values,
        historico: resultado
      });
    }
    fetchData();
  }, [selectedLocalId])

  /*
  const handleVerPerfil = id => {
    props.history.push('/bestas/' + id)
  };*/

  const loading = values.historico.length === 0;

  const local = locais.find(local => local.id === selectedLocalId);
  const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

  return (
    <Portlet className={clsx(classes.root, className)}>
      <PortletContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Início</TableCell>
                  <TableCell>Autor</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              {loading ? null :
              <TableBody>
                {Array.from(values.historico).map((timestamp, i) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={timestamp["locais_praxes"].id}
                  >
                    <TableCell>
                      <Typography className={classes.nameText} variant="body1">
                        <Moment format="D [de] MMMM">
                          {validateDataReact(timestamp["locais_praxes"].created_at)}
                        </Moment>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.nameText} variant="body1">
                        <Moment format="HH:mm">{validateDataReact(timestamp["locais_praxes"].created_at)}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={IMAGES_URL + timestamp["engenheiros"].foto}
                        ></Avatar>
                        <Typography
                          className={classes.nameText}
                          variant="body1"
                        >
                          {timestamp["engenheiros"].numDisquete} {timestamp["engenheiros"].nomePraxe}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <ApagarIcon color="primary" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>}
            </Table>
          </div>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
};

export default withRouter(Historico);
