import React from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Portlet, PortletContent } from "../../../../components";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button
} from "@material-ui/core";

import { ArrowRight as ArrowRightIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tableRow: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0
    }
  },
  inner: {
    minWidth: 1050,
    maxHeight: 360
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

// Ver documentação Material-UI, secção "Classificando & Selecionando"
// https://material-ui.com/pt/components/tables/

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

// 'id' determina a propriedade para ordenar cada uma das colunas

const headRows = [
  { id: "numDisquete", label: "Nome" },
  { id: "primeiroNome", label: "Alcunha" },
  { id: "telemovel", label: "Telemóvel" },
  { id: "numAluno", label: "Número de Aluno" },
  { id: "horario", label: "Horário" },
  { id: "cidade", label: "Cidade" }
  // {id: 'Ações', label: 'Ações'},
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ações</TableCell>
      </TableRow>
    </TableHead>
  );
}

const TabelaBestas = props => {
  const { className, bestas, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("numDisquete");

  const handleVerPefil = id => {
    props.history.push("/bestas/" + id);
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  return (
    <Portlet className={clsx(classes.root, className)}>
      <PortletContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(bestas, getSorting(order, orderBy)).map(
                  (besta, i) => {
                    return (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={besta.id}
                      >
                        <TableCell>
                          <div className={classes.nameContainer}>
                            <Avatar
                              className={classes.avatar}
                              src={besta.foto}
                            ></Avatar>
                            <Typography
                              className={classes.nameText}
                              variant="h5"
                            >
                              {besta.numDisquete} {besta.nomePraxe}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={classes.nameText}
                            variant="body1"
                          >
                            {besta.primeiroNome} {besta.ultimoNome}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={classes.nameText}
                            variant="body1"
                          >
                            {besta.telemovel}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={classes.nameText}
                            variant="body1"
                          >
                            {besta.numAluno}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={classes.nameText}
                            variant="body1"
                          >
                            {besta.horario}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            className={classes.nameText}
                            variant="body1"
                          >
                            {besta.cidade}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={event => handleVerPefil(besta.id)}
                          >
                            Ver Perfil <ArrowRightIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
};

export default withRouter(TabelaBestas);
