import React, { useState, useEffect, Fragment } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import data from "../../../../data/bestas";
import { Button, Typography, Avatar } from "@material-ui/core";
import { ArrowRight as ArrowRightIcon } from "@material-ui/icons";
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {},
  portletContent: {
    paddingTop: "0"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  product: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    "&:not(:first-of-type)": {
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  productImageWrapper: {
    borderRadius: "5px",
    overflow: "hidden",
    height: "64px",
    width: "64px"
  },
  productImage: {
    width: "100%",
    height: "100%"
  },
  productDetails: {
    marginLeft: theme.spacing(2),
    flexGrow: 1
  },
  productTitle: {},
  productTimestamp: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const Aniversarios = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const handleVerPefil = id => {
    props.history.push("/bestas/" + id);
  };

  const[values, setValues] = useState({
    bestas: []
  })

  useEffect(() => {
    const fetchAniversarios = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/bestas/aniversarios`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

        resultado.sort(function(a, b){
          return new Date(a.dataNascimento) - new Date(b.dataNascimento)
        });

      setValues({
        ...values,
        bestas: resultado.filter(besta => besta.naPraxe == 1)
      })
    }
    fetchAniversarios();
  }, [])

  const loading = values.bestas.length === 0;

  return (
    <Portlet className={rootClassName}>
      <PortletHeader noDivider>
        <PortletLabel title="Próximos Aniversários" />
      </PortletHeader>
      <PortletContent className={classes.portletContent}>
      { loading ? null :
        <Fragment>
          {Array.from(values.bestas).map((besta, i) => (
            <div className={classes.product} key={i}>
              <div className={classes.productImageWrapper}>
                <Avatar
                  alt="Product Name"
                  className={classes.productImage}
                  src={besta.foto}
                />
              </div>
              <div className={classes.productDetails}>
                <Link to="#">
                  <Typography className={classes.productTitle} variant="h4">
                    {besta.numDisquete} {besta.nomePraxe}
                  </Typography>
                </Link>
                <Typography
                  className={classes.productTimestamp}
                  variant="body1"
                >
                  <Moment format="D [de] MMMM">{besta.dataNascimento}</Moment>
                </Typography>
              </div>
              <div>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={event => handleVerPefil(besta.id)}
                >
                  Ver Perfil <ArrowRightIcon />
                </Button>
              </div>
            </div>
          ))}
        </Fragment>}
      </PortletContent>
    </Portlet>
  );
};

export default withRouter(Aniversarios);
