import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Typography,
  Container
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.default
    }
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    marginTop: theme.spacing(6)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "63px",
    flexShrink: 0
  },
  logoLink: {
    fontSize: 0
  },
  logoImage: {
    cursor: "pointer",
    width: "175px",
    height: "175px"
  },
  logoDivider: {
    marginBottom: theme.spacing(2)
  }
}));

const IniciarSessao = props => {
  const classes = useStyles();

  const [values, setValues] = useState({
    email: localStorage.getItem("email"),
    password: '',
    invalidos: false,
    lembrar: localStorage.getItem("email") === null ? false : true,
  });


  const handleLogin = event => {
    event.preventDefault();
    const body = {'email': values.email, 'password': values.password}
    const API_URL = process.env.REACT_APP_API_URL;
    fetch(`${API_URL}/v1/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      if(data.hasOwnProperty('access_token')) {
        if(values.lembrar) {
          localStorage.setItem("email", values.email);
        } else {
          localStorage.removeItem("email");
        }
        const user = {
          id: data.user.id,
          nomePraxe: data.user.nomePraxe,
          grauHierarquico: data.user.grauHierarquico,
          foto: data.user.foto
        }
        console.log(data)
        localStorage.setItem("user", JSON.stringify(user))
        localStorage.setItem("access_token", JSON.stringify(data.access_token));

        return props.history.push("/dashboard")
      }
      else {
        setValues({
          ...values,
          invalidos: true
        })
      }
    })
  };

  const handleSelect = event => {
    setValues({
      ...values,
      invalidos: false,
      [event.target.name]: event.target.value
    });
  }

  const handleSelectLembrar = () => {
    setValues({
      ...values,
      lembrar: !values.lembrar
    })
  }


  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <div className={classes.logoWrapper}>
          <Link className={classes.logoLink} to="/">
            <img
              alt="Curso logo"
              className={classes.logoImage}
              src="/images/logos/curso.png"
            />
          </Link>
        </div>
        <div className={classes.title}>
          <Typography component="h1" variant="h5">
            Iniciar Sessão
          </Typography>
        </div>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleSelect}
            value={values.email}
            error={values.invalidos}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Palavra-passe"
            defaultValue={values.password}
            onChange={handleSelect}
            type="password"
            id="password"
            autoComplete="current-password"
            error={values.invalidos}
            helperText={values.invalidos ? "Email ou palavra-passe incorretos" : ""}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar"
            checked={values.lembrar}
            onChange={handleSelectLembrar}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
          >
            Iniciar Sessão
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default IniciarSessao;
