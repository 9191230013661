import React from "react";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import engenheiros from "../../../../data/engenheiros";

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Divider,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemSecondaryAction,
  Checkbox,
  ListItemAvatar
} from "@material-ui/core";
import { Portlet } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  margin: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1)
  },
  avatar: {
    marginBottom: theme.spacing(2),
    height: "125px",
    width: "125px",
    flexShrink: 0,
    flexGrow: 0
  },
  presencas: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    overflow: "auto"
  },
  headerPresencas: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  editarButton: {
    marginRight: theme.spacing(1)
  }
}));

const DadosReuniao = props => {
  const {
    open,
    onHandleSelect,
    onHandleOpen,
    onHandleClose,
    onHandleData,
    onHandleDiaDisquete,
    onHandleDataAntiPraxe,
    ...values
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            fullWidth
            margin="dense"
            inputVariant="outlined"
            label="Data"
            name="data"
            value={values.dataHora}
            onChange={onHandleData}
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          select
          autoFocus
          label="Moderador"
          name="moderador"
          value={values.moderador}
          onChange={onHandleSelect}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
        >
          {engenheiros.map((engenheiro, i) => {
            return (
              <MenuItem value={engenheiro.nomePraxe}>
                {engenheiro.nomePraxe}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Local"
          margin="dense"
          name="local"
          onChange={onHandleSelect}
          defaultValue={values.local}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

const Presencas = props => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState([1]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Grid container spacing={3} justify="center">
      <Grid item md={6} sm={6} xs={12}>
        <Portlet>
          <List dense className={classes.presencas}>
            {engenheiros.map((engenheiro, i) => {
              return (
                <ListItem
                  key={engenheiro.id}
                  button
                  onClick={handleToggle(engenheiro.id)}
                >
                  <ListItemAvatar>
                    <Avatar src={engenheiro.foto} />
                  </ListItemAvatar>
                  <Typography className={classes.text} variant="h6">
                    {engenheiro.numDisquete} {engenheiro.nomePraxe}
                  </Typography>
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      color="primary"
                      onChange={handleToggle(engenheiro.id)}
                      checked={checked.indexOf(engenheiro.id) !== -1}
                      inputProps={{ "aria-labelledby": engenheiro.id }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Portlet>
      </Grid>
    </Grid>
  );
};

const TopicosComentarios = props => {
  const {
    open,
    onHandleSelect,
    onHandleOpen,
    onHandleClose,
    onHandleDataNascimento,
    onHandleDiaDisquete,
    onHandleDataAntiPraxe,
    ...values
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            fullWidth
            multiline
            label="Tópico"
            name="topico"
            className={classes.textField}
            value={values.topico}
            onChange={onHandleSelect}
            margin="dense"
            variant="outlined"
          />
        </form>
      </Grid>
      <Grid item md={12} xs={12}>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            fullWidth
            multiline
            label="Discussão"
            name="discussao"
            className={classes.textField}
            value={values.discussao}
            onChange={onHandleSelect}
            margin="dense"
            variant="outlined"
          />
        </form>
      </Grid>
      <Grid item md={12} xs={12}>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            fullWidth
            multiline
            label="Comentários"
            name="comentarios"
            className={classes.textField}
            value={values.comentarios}
            onChange={onHandleSelect}
            margin="dense"
            variant="outlined"
          />
        </form>
      </Grid>
    </Grid>
  );
};

const EditarReuniao = props => {
  const { open, onHandleOpen, onHandleClose } = props;

  const classes = useStyles();

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={onHandleOpen}
        className={classes.editarButton}
      >
        Editar Reunião
      </Button>
      <Dialog
        open={open}
        onClose={event => onHandleClose(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Editar Reunião</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Dados da Reunião
          </DialogContentText>
          <DadosReuniao {...props} />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Presenças
          </DialogContentText>
          <Presencas {...props} />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Tópicos e Comentários
          </DialogContentText>
          <TopicosComentarios {...props} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={event => onHandleClose(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={event => onHandleClose(true)} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditarReuniao;
