import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import {
  ToolbarEstatisticas,
  TabelaEngenheiros,
  NaPraxe,
  AntiPraxe,
  EstatisticasMes,
  DesistenciasDiaSemana,
  TabelaBestas,
  TabelaBestasPresencas
} from "./components";
import bestas from "../../data/bestas";
import engenheiros from "../../data/engenheiros";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  item: {
    height: "100%"
  }
}));

const Estatisticas = props => {
  const classes = useStyles();

  const [values, setValues] = useState({
    bestas: bestas,
    engenheiros: engenheiros,
    tab: 0
  });

  const handleTabChange = (event, value) => {
    setValues({
      ...values,
      tab: value
    });
  };

  const [data, setData] = useState({ engenheiros: [], bestas: [] });

  const sortOrder = [
    'Engenheiro',
    'Bispo',
    'Cardeal',
    'Cardeal de Curso',
    'Super-Engenheiro'
  ];

  useEffect(() => {
    var id = props.match.params.id;

    const fetchBestasEngenheiros = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultadoEngenheiros = await fetch(`${API_URL}/v1/engenheiros`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())

      var resultadoBestas = await fetch(`${API_URL}/v1/bestas`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())

      // Ordena a lista de engenheiros por ordem de disquete
      resultadoEngenheiros.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      // Posteriormente, ordena a lista de engenheiros por grau hierárquico
      resultadoEngenheiros.sort(function(a, b) {
        return sortOrder.indexOf(a.grauHierarquico) - sortOrder.indexOf(b.grauHierarquico);
      })

      // Ordenar a lista de bestas por ordem de disquete
      resultadoBestas.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      resultadoBestas = resultadoBestas.filter(function (a) {
        return a.naPraxe === 1
      });

      setData({
        ...data,
        engenheiros: resultadoEngenheiros,
        bestas: resultadoBestas
      });
    };
    fetchBestasEngenheiros();
  }, [values.update]);

  return (
    <DashboardLayout title="Estatísticas">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <ToolbarEstatisticas
              className={classes.item}
              tab={values.tab}
              onHandleTabChange={handleTabChange}
            />
          </Grid>
        </Grid>
        {values.tab === 0 ? (
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <TabelaBestas bestas={data.bestas} />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <NaPraxe className={classes.item} />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <AntiPraxe className={classes.item} />
            </Grid>
            {/*<Grid item lg={6} sm={6} xl={6} xs={12}>
              <EstatisticasMes className={classes.item} />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <DesistenciasDiaSemana className={classes.item} />
            </Grid>*/}
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <TabelaBestasPresencas bestas={data.bestas} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <TabelaEngenheiros engenheiros={data.engenheiros} />
            </Grid>
          </Grid>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Estatisticas;
