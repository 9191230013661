import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import data from "../../data/topicos";
import { ProcurarTopicos, ListaTopicos, Detalhes } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Topicos = props => {
  const classes = useStyles();

  // const api = useContext(MyContext)

  const [values, setValues] = useState({
    topicos: data,
    selectedIndex: 0,
    selectedTopicoId: "1"
  });

  useEffect(() => {
    var id = props.match.params.id;

    setValues({
      topicos: data,
      selectedIndex: id - 1,
      selectedTopicoId: id
    });
  }, [props.match.params.id]);

  /*
  useEffect(() => {
    var id = props.match.params.id;

    fetch('http://127.0.0.1:3333/api/v1' + 'topicos')
      .then(response => response.json())
      .then(data => setValues ({
        topicos: data,
        selectedIndex: id - 1,
        selectedEngenheiroId: id,
        open: false,
      }))
  })*/

  const handleSelect = (selectedIndex, selectedTopicoId) => {
    setValues({
      topicos: data,
      selectedIndex,
      selectedTopicoId
    });
    props.history.push("/topicos/" + selectedTopicoId);
  };

  return (
    <DashboardLayout title="Tópicos">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            <ProcurarTopicos selectedBesta={values.selectedBesta} />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            <ListaTopicos
              onHandleSelect={handleSelect}
              selectedIndex={values.selectedIndex}
              topicos={values.topicos}
            />
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={12}>
            <Detalhes
              onHandleSelect={handleSelect}
              selectedTopicoId={values.selectedTopicoId}
              topicos={values.topicos}
            />
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

Topicos.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withRouter(Topicos);
