import React from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Typography
} from "@material-ui/core";
import { Portlet, PortletContent } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 470,
    overflow: "auto"
  },
  tableRow: {
    height: "64px"
  },
  scroll: {
    maxHeight: "100%",
    overflow: "auto"
  },
  tableCell: {
    whiteSpace: "nowrap"
  },
  tableCellInner: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-flex",
    fontSize: "14px",
    fontWeight: 500,
    height: "36px",
    width: "36px"
  },
  nameText: {
    display: "inline-block",
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: "pointer"
  }
}));

const ListaEngenheiros = props => {
  const { className, selectedIndex, filtro,  onHandleSelect, engenheiros } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

  const loweredCasedFiltro = filtro.toLowerCase()

  const filteredEngenheiros = engenheiros.filter(item => {
      return Object.keys(item).some(key =>
        typeof item[key] === "string" && item[key].toLowerCase().includes(loweredCasedFiltro)
      );
    });

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <PerfectScrollbar>
          <List className={classes.root}>
            {filteredEngenheiros.map((engenheiro, i) => (
              <ListItem
                key={i}
                button
                selected={selectedIndex === i}
                onClick={event => onHandleSelect(i, engenheiro.id)}
              >
                <ListItemAvatar>
                  <Avatar src={IMAGES_URL + engenheiro.foto} />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.nameText} variant="h5">
                      {engenheiro.numDisquete} {engenheiro.nomePraxe}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </PerfectScrollbar>
      </PortletContent>
    </Portlet>
  );
};

export default ListaEngenheiros;
