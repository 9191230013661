import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button } from "@material-ui/core";
import { Portlet, PortletContent } from "../../../../components";
import { ArrowRight as ArrowRightIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    marginTop: theme.spacing(1),
    display: "flex"
  },
  reportTitle: {
    marginTop: theme.spacing(2)
  },
  reportText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  planeamento: {
    marginLeft: theme.spacing(2)
  },
  text: {
    color: theme.palette.text.secondary
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: "auto",
    height: "150px",
    width: "150px"
  },
  iconBackground: {
    marginLeft: 5,
    backgroundColor: theme.palette.primary.main,
    height: 30,
    width: 30,
    alignItems: "center"
  },
  icon: {
    height: 15,
    width: 15
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
}));

const Detalhes = props => {
  const { className, selectedTopicoId, topicos, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const topico = topicos.find(topico => topico.id === selectedTopicoId);

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletContent>
        <div className={classes.header}>
          <Typography variant="h2">{topico.nome}</Typography>
        </div>
        <div className={classes.reportTitle}>
          <Typography variant="h5">
            Reunião de 5 de Setembro
            <Button
              color="primary"
              size="small"
              variant="text"
              //onClick={event => handleVerPefil(besta.id)}
            >
              Ver Reunião <ArrowRightIcon />
            </Button>
          </Typography>

          <Typography className={classes.reportText} variant="body1">
            {topico.descricao}
          </Typography>
        </div>
        <div className={classes.reportTitle}>
          <Typography variant="h5">
            Reunião de 10 de Setembro
            <Button
              color="primary"
              size="small"
              variant="text"
              //onClick={event => handleVerPefil(besta.id)}
            >
              Ver Reunião <ArrowRightIcon />
            </Button>
          </Typography>
          <Typography className={classes.reportText} variant="body1">
            {topico.descricao}
          </Typography>
        </div>
      </PortletContent>
    </Portlet>
  );
};

Detalhes.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  praxe: PropTypes.array.isRequired
};

export default Detalhes;
