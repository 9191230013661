import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Portlet, PortletContent } from "../../../../components";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { options } from "./chart";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  chartContainer: {
    height: "393px",
    position: "relative"
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const data = {
  labels: [
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio"
  ],
  datasets: [
    {
      label: "Na Praxe",
      backgroundColor: "rgb(75, 192, 192)",
      data: [140, 80, 60, 50, 45, 45, 45, 45, 45]
    },
    {
      label: "Desistências",
      backgroundColor: "rgb(255, 99, 132)",
      data: [60, 20, 10, 5, 0, 0, 0, 0, 0, 0]
    }
  ]
};

const LatestSales = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const [values, setValues] = useState({
    numNaPraxe: [],
    numAP: [],
  })

  useEffect(() => {
    const fetchData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/bestas/estatisticasMes`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        numNaPraxe: resultado.numNaPraxe,
        numAP: (resultado.numAP / resultado.numAP) * 100,
      });
    }
    fetchData();
  }, [])

  const loading = values.numNaPraxe.length == 0 || values.numAP.length == 0

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletContent>
        <Typography className={classes.title} variant="h5">
          Estatísticas por Mês
        </Typography>
        <div className={classes.chartContainer}>
          <Bar data={data} options={options} />
        </div>
      </PortletContent>
    </Portlet>
  );
};

export default LatestSales;
