import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import { SearchInput } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: "42px",
    alignItems: "center"
  },
  spacer: {
    flexGrow: 1
  },
  editarButton: {
    marginRight: theme.spacing(1)
  }
}));

const ProcurarTopicos = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <SearchInput placeholder="Procurar tópicos" />
      </div>
    </div>
  );
};

ProcurarTopicos.propTypes = {
  className: PropTypes.string
};

export default ProcurarTopicos;
