import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Portlet,
  PortletHeader,
  PortletContent,
  PortletFooter
} from "../../../../components";
import { Button, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
  footer: {
    display: "flex",
    flexDirection: "row-reverse"
  }
}));

const AlterarPassword = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: ""
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleConfirmar = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    fetch(`${API_URL}/v1/engenheiros/changePassword`, {
      method: 'PATCH',
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'oldPassword': values.currentPassword,
        'newPassword': values.newPassword
      })
    })
    .then(async response => {
      const responsePayload = await response.json();

      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (responsePayload && responsePayload.message) || "Error";
        return Promise.reject(error);
      }
      props.history.push('/dashboard');
    })
    .catch((error) => {
      alert( JSON.stringify( error ));
    })
  }

  return (
    <Portlet {...rest} className={clsx(classes.root, className)}>
      <form>
        <PortletHeader>
          <Typography variant="h5">Alterar Palavra-passe</Typography>
        </PortletHeader>
        <PortletContent>
          <TextField
            fullWidth
            required
            label="Palavra-passe atual"
            name="currentPassword"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            error={values.currentPassword !== "" && values.currentPassword === values.newPassword}
            helperText={values.currentPassword !== "" && values.currentPassword === values.newPassword ? "Palavras-passe coincidem" : ""}
          />
          <TextField
            fullWidth
            required
            label="Nova Palavra-passe"
            name="newPassword"
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
            type="password"
            value={values.newPassword}
            variant="outlined"
            inputProps={{ minLength: 5 }}
            error={values.newPassword.length !== 0 && values.currentPassword === values.newPassword}
            helperText={values.newPassword.length !== 0 && values.currentPassword === values.newPassword ? "Palavras-passe coincidem" : ""}
          />
        </PortletContent>
        <PortletFooter className={classes.footer}>
          <Button
            onClick={event => handleConfirmar()}
            type={"button"}
            color="primary"
            disabled={
              values.currentPassword.length === 0 ||
              values.newPassword.length === 0 ||
              values.currentPassword === values.newPassword
            }
          >
            Alterar
          </Button>
        </PortletFooter>
      </form>
    </Portlet>
  );
};

AlterarPassword.propTypes = {
  className: PropTypes.string
};

export default withRouter(AlterarPassword);
