import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { SearchInput } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: "42px",
    alignItems: "center"
  },
  spacer: {
    flexGrow: 1
  },
  editarButton: {
    marginRight: theme.spacing(1)
  }
}));

const ProcurarLocais = props => {
  const { className, filtro, onHandleSelectFiltro } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Procurar locais"
          name="filtro"
          value={filtro}
          onChange={onHandleSelectFiltro}
        />
      </div>
    </div>
  );
};

export default ProcurarLocais;
