import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Avatar,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Tooltip
} from "@material-ui/core";
import { ExpandMoreOutlined as ExpandirIcon } from "@material-ui/icons";
import { Expansion } from "../../../../components";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  dropdownButton: {
    marginRight: -theme.spacing(2)
  },
  chartWrapper: {
    height: "400px",
    position: "relative"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    margin: theme.spacing(2)
  },
  avatar: {
    margin: 5,
    width: 60,
    height: 60
  }
}));

const Presencas = props => {
  const { selectedPraxeId } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    manha: [],
    almoco: [],
    jantar: []
  });

  useEffect(() => {
    const fetchPresencas = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/praxes/${selectedPraxeId}/presencas`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      resultado.manha.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      resultado.almoco.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      resultado.jantar.sort(function(a, b){
        return a.numDisquete - b.numDisquete
      });

      setValues({
        ...values,
        manha: resultado.manha,
        almoco: resultado.almoco,
        jantar: resultado.jantar
      });
    }

    fetchPresencas();
  }, [selectedPraxeId])

  const loading = values.manha.length === 0
                  && values.almoco.length === 0
                  && values.jantar.length === 0

  const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Presenças</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container align="center">
            <Grid lg={12} sm={12} xs={12} md={12} xl={12}>
              <Typography variant="h6" className={classes.secondaryHeading}>
                Manhã{" "}({values.manha.length})
              </Typography>
            </Grid>
            {loading ? null :
            <Grid container justify="row">
              {values.manha.map((besta, i) => (
                <Tooltip title={besta.numDisquete}>
                  <Avatar
                    src={IMAGES_URL + besta.foto}
                    className={classes.avatar}
                    component={RouterLink}
                    to={"/bestas/" + besta.id}
                  />
                </Tooltip>
              ))}
            </Grid>}
            <Grid lg={12} sm={12} xs={12} md={12} xl={12}>
              <Typography variant="h6" className={classes.secondaryHeading}>
                Almoço{" "}({values.almoco.length})
              </Typography>
            </Grid>
            { loading ? null :
            <Grid container justify="row">
              {values.almoco.map((besta, i) => (
                <Tooltip title={besta.numDisquete}>
                  <Avatar
                    src={IMAGES_URL + besta.foto}
                    className={classes.avatar}
                    component={RouterLink}
                    to={"/bestas/" + besta.id}
                  />
                </Tooltip>
              ))}
            </Grid>}
            <Grid lg={12} sm={12} xs={12} md={12} xl={12}>
              <Typography variant="h6" className={classes.secondaryHeading}>
                Jantar{" "}({values.jantar.length})
              </Typography>
            </Grid>
            {loading ? null :
            <Grid container justify="row">
              {values.jantar.map((besta, i) => (
                <Tooltip title={besta.numDisquete}>
                  <Avatar
                    src={IMAGES_URL + besta.foto}
                    className={classes.avatar}
                    component={RouterLink}
                    to={"/bestas/" + besta.id}
                  />
                </Tooltip>
              ))}
            </Grid>}
          </Grid>
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};

export default withRouter(Presencas);
