import React from "react";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  details: {
    display: "flex"
  },
  info: {},
  locationText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    marginLeft: "auto",
    height: "150px",
    width: "150px",
    flexShrink: 0,
    flexGrow: 0
  },
  progressWrapper: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const EditarPraxeEspecial = props => {
  const {
    open,
    selectedPraxeEspecial,
    selectedDate,
    onHandleSelectPraxeEspecial,
    onHandleSelectDate,
    onHandleOpen,
    onHandleClose
  } = props;

  const classes = useStyles();

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={onHandleOpen}>
        Editar
      </Button>
      <Dialog
        open={open}
        onClose={event => onHandleClose(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={false}
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">
          Editar Próxima Praxe Especial
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Indica o nome e data da próxima Praxe Especial.
          </DialogContentText>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              fullWidth
              autoFocus={true}
              label="Local da Praxe Especial"
              className={classes.textField}
              value={selectedPraxeEspecial}
              onChange={onHandleSelectPraxeEspecial}
              margin="normal"
              variant="outlined"
            />
          </form>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              margin="normal"
              inputVariant="outlined"
              label="Data da Praxe Especial"
              value={selectedDate}
              onChange={onHandleSelectDate}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => onHandleClose(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={event => onHandleClose(true)} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditarPraxeEspecial;
