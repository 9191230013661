import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Button
} from "@material-ui/core";
import {
  ExpandMoreOutlined as ExpandirIcon,
  ArrowRight as ArrowRightIcon
} from "@material-ui/icons";
import { Expansion } from "../../../../components";
import { validateDataReact } from '../../../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  content: {
    padding: 0
  },
  tableRow: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0
    }
  },
  inner: {
    minWidth: 964
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const Localizacoes = props => {
  const { selectedPraxeId } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    localizacoes: []
  });

  const handleVerLocal = id => {
    props.history.push("/locais/" + id);
  };

  useEffect(() => {
    const fetchLocalizacoes = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/praxes/${selectedPraxeId}/localizacao`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            //props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        localizacoes: resultado
      });
    }

    fetchLocalizacoes();
  }, [selectedPraxeId])

  const loading = values.localizacoes.length === 0
  const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Localizações</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Início</TableCell>
                    <TableCell>Autor</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {values.localizacoes.map((localizacao, i) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={localizacao.id}
                  >
                    <TableCell>
                      <Typography className={classes.nameText} variant="h5">
                        {localizacao.local}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.nameText} variant="body1">
                        <Moment format="HH:mm">{validateDataReact(localizacao.created_at)}</Moment>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={IMAGES_URL + localizacao.foto}
                        ></Avatar>
                        <Typography
                          className={classes.nameText}
                          variant="body1"
                        >
                          {localizacao.nomePraxe}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={event => handleVerLocal(localizacao.id)}
                      >
                        Ver Localização <ArrowRightIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};

export default withRouter(Localizacoes);
