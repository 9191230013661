import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from "@material-ui/core";
import { ExpandMoreOutlined as ExpandirIcon } from "@material-ui/icons";
import { Expansion } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  dropdownButton: {
    marginRight: -theme.spacing(2)
  },
  chartWrapper: {
    height: "400px",
    position: "relative"
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

const Comentarios = props => {
  const { selectedPraxeId, praxes } = props;

  const classes = useStyles();

  const praxe = praxes.find(praxe => praxe.id == selectedPraxeId);

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Comentários</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>{praxe.comentarios}</Typography>
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};

export default Comentarios;
