import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { checkNullReturnBlank } from '../../../../../../helpers/helperFunctions'

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Divider,
  Avatar
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  margin: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1)
  },
  avatar: {
    marginBottom: theme.spacing(2),
    height: "125px",
    width: "125px",
    flexShrink: 0,
    flexGrow: 0
  }
}));

const DadosPessoais = props => {
  const {
    onHandleSelect,
    onHandleDataNascimento,
    ...values
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          disabled
          required
          label="Número de Disquete"
          margin="dense"
          name="numDisquete"
          onChange={onHandleSelect}
          defaultValue={values.numDisquete}
          variant="outlined"
          error={values.numDisquete.length === 0 ? true : false}
          helperText={values.numDisquete.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          autoFocus
          fullWidth
          required
          label="Nome de Praxe"
          margin="dense"
          name="nomePraxe"
          onChange={onHandleSelect}
          defaultValue={values.nomePraxe}
          variant="outlined"
          error={values.nomePraxe.length === 0 ? true : false}
          helperText={values.nomePraxe.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Primeiro Nome"
          margin="dense"
          name="primeiroNome"
          onChange={onHandleSelect}
          value={values.primeiroNome}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Último Nome"
          margin="dense"
          name="ultimoNome"
          onChange={onHandleSelect}
          value={values.ultimoNome}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Outros Nomes"
          margin="dense"
          name="outrosNomes"
          onChange={onHandleSelect}
          defaultValue={values.outrosNomes}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin="dense"
            inputVariant="outlined"
            label="Data de Nascimento"
            name="dataNascimento"
            value={values.dataNascimento}
            onChange={onHandleDataNascimento}
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

const MoradaContactos = props => {
  const {
    onHandleSelect,
    ...values
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Cidade"
          margin="dense"
          name="cidade"
          onChange={onHandleSelect}
          defaultValue={values.cidade}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Freguesia"
          margin="dense"
          name="freguesia"
          onChange={onHandleSelect}
          defaultValue={values.freguesia}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Rua"
          margin="dense"
          name="rua"
          onChange={onHandleSelect}
          defaultValue={values.rua}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Email"
          margin="dense"
          name="email"
          onChange={onHandleSelect}
          defaultValue={values.email}
          variant="outlined"
          error={values.email.length === 0 ? true : false}
          helperText={values.email.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Telemóvel"
          margin="dense"
          name="telemovel"
          onChange={onHandleSelect}
          defaultValue={values.telemovel}
          variant="outlined"
          error={values.telemovel.length === 0 ? true : false}
          helperText={values.telemovel.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Facebook ID"
          margin="dense"
          name="facebookID"
          onChange={onHandleSelect}
          defaultValue={values.facebookID}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

const UniversidadePraxe = props => {
  const {
    onHandleSelect,
    ...values
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          required
          label="Número de Aluno"
          margin="dense"
          name="numAluno"
          onChange={onHandleSelect}
          defaultValue={values.numAluno}
          variant="outlined"
          error={values.numAluno.length === 0 ? true : false}
          helperText={values.numAluno.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          select
          required
          label="Grau Hierárquico"
          name="grauHierarquico"
          value={values.grauHierarquico}
          onChange={onHandleSelect}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
          error={values.grauHierarquico.length === 0 ? true : false}
          helperText={values.grauHierarquico.length === 0 ? "Campo de preenchimento obrigatório" : ""}
        >
          <MenuItem value="Engenheiro">Engenheiro</MenuItem>
          <MenuItem value="Bispo">Bispo</MenuItem>
          <MenuItem value="Cardeal">Cardeal</MenuItem>
          <MenuItem value="Cardeal de Curso">Cardeal de Curso</MenuItem>
          <MenuItem value="Super-Engenheiro">Super-Engenheiro</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

const EditarPerfil = props => {
  const {
    engenheiro,
    openEditar,
    onHandleOpenEditar,
    onHandleCloseEditar
  } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    numDisquete: '',
    nomePraxe: '',
    grauHierarquico: '',
    telemovel: '',
    email: '',
    numAluno: ''
  });

  useEffect(() => {
    setValues({
      ...values,
      numDisquete: checkNullReturnBlank(engenheiro.numDisquete),
      nomePraxe: checkNullReturnBlank(engenheiro.nomePraxe),
      primeiroNome: checkNullReturnBlank(engenheiro.primeiroNome),
      ultimoNome: checkNullReturnBlank(engenheiro.ultimoNome),
      outrosNomes: checkNullReturnBlank(engenheiro.outrosNomes),
      foto: checkNullReturnBlank(engenheiro.foto),
      dataNascimento: checkNullReturnBlank(engenheiro.dataNascimento),
      telemovel: checkNullReturnBlank(engenheiro.telemovel),
      email: checkNullReturnBlank(engenheiro.email),
      idCidade: checkNullReturnBlank(engenheiro.cidade),
      freguesia: checkNullReturnBlank(engenheiro.freguesia),
      rua: checkNullReturnBlank(engenheiro.rua),
      facebookID: checkNullReturnBlank(engenheiro.facebookID),
      numAluno: checkNullReturnBlank(engenheiro.numAluno),
      grauHierarquico: checkNullReturnBlank(engenheiro.grauHierarquico)
    })
  }, [openEditar])

  const handleSelect = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDataNascimento = dataNascimento => {
    setValues({
      ...values,
      dataNascimento
    });
  };

  const handleSelectFoto = event => {
    setValues({
      ...values,
      foto: event.target.files[0],
      fotoPreview: URL.createObjectURL(event.target.files[0])
    })
  }

  const handleRemoveFile = (e, propriedade) => {
    setValues({
      ...values,
      [propriedade]: ''
    })
  }


  const handleLimparCampos = () => {
    setValues({
      numDisquete: checkNullReturnBlank(engenheiro.numDisquete),
      nomePraxe: checkNullReturnBlank(engenheiro.nomePraxe),
      primeiroNome: checkNullReturnBlank(engenheiro.primeiroNome),
      ultimoNome: checkNullReturnBlank(engenheiro.ultimoNome),
      outrosNomes: checkNullReturnBlank(engenheiro.outrosNomes),
      foto: checkNullReturnBlank(engenheiro.foto),
      //fotoPreview: URL.createObjectURL(engenheiro.foto),
      dataNascimento: null,
      telemovel: checkNullReturnBlank(engenheiro.telemovel),
      email: checkNullReturnBlank(engenheiro.email),
      idCidade: checkNullReturnBlank(engenheiro.cidade),
      freguesia: checkNullReturnBlank(engenheiro.freguesia),
      rua: checkNullReturnBlank(engenheiro.rua),
      facebookID: checkNullReturnBlank(engenheiro.facebookID),
      numAluno: checkNullReturnBlank(engenheiro.numAluno),
      grauHierarquico: checkNullReturnBlank(engenheiro.grauHierarquic),
    })
  }

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={onHandleOpenEditar}
      >
        Editar Perfil
      </Button>
      <Dialog
        open={openEditar}
        onClose={event => {
            onHandleCloseEditar(false);
            handleLimparCampos();
          }
        }
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Editar Perfil</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} src={values.fotoPreview} />
          </div>
          <div className={classes.button}>
          <input
            accept="image/*"
            type="file"
            id="foto-input"
            name="foto"
            style={{ display: 'none' }}
            onChange={handleSelectFoto}
          />
          <label htmlFor="foto-input">
            <Button
              color="primary"
              size="small"
              variant="text"
              component="span"
            >
              Adicionar Foto
            </Button>
          </label>
          <Button
            size="small"
            variant="text"
            component="span"
            onClick={(e) => {
                handleRemoveFile(e, 'foto');
                handleRemoveFile(e, 'fotoPreview');
              }
            }
          >
            Remover Foto
          </Button>
          </div>
          <DialogContentText align="center" variant="h6">
            Dados Pessoais
          </DialogContentText>
          <DadosPessoais
            onHandleSelect={handleSelect}
            onHandleDataNascimento={handleDataNascimento}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Morada e Contactos
          </DialogContentText>
          <MoradaContactos
            onHandleSelect={handleSelect}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Universidade e Praxe
          </DialogContentText>
          <UniversidadePraxe
            onHandleSelect={handleSelect}
            {...values}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={event => {
                onHandleCloseEditar(false, null);
                handleLimparCampos();
            }
          }
          color="primary">
            Cancelar
          </Button>
          <Button
            onClick={event => onHandleCloseEditar(true, values)}
            color="primary"
            disabled={values.numDisquete.length === 0
              || values.nomePraxe.length === 0
              || values.grauHierarquico.length === 0
              || values.telemovel.length === 0
              || values.email.length === 0
              || values.numAluno.length === 0 ? true : false}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditarPerfil;
