import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel
} from "@material-ui/core";
import {
  ExpandMoreOutlined as ExpandirIcon,
  ArrowRight as ArrowRightIcon,
  CheckOutlined as PresenteIcon,
  CloseOutlined as FaltaIcon
} from "@material-ui/icons";
import { Expansion } from "../../../../components";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  dropdownButton: {
    marginRight: -theme.spacing(2)
  },
  chartWrapper: {
    height: "400px",
    position: "relative"
  },
  tableRow: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0
    }
  },
  inner: {
    minWidth: 350 //964
  },
  portletFooter: {
    display: "flex",
    justifyContent: "flex-end"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  content: {
    padding: 0
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

// Ver documentação Material-UI, secção "Classificando & Selecionando"
// https://material-ui.com/pt/components/tables/

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

// 'id' determina a propriedade para ordenar cada uma das colunas

const headRows = [
  { id: "nome", label: "Nome da Praxe" },
  { id: "presenca", label: "Presença" }
  // {id: 'Ações', label: 'Ações'},
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ações</TableCell>
      </TableRow>
    </TableHead>
  );
}

/*
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};*/

const PraxesEspeciais = props => {
  const { className, selectedBestaId, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  const handleVerPraxe = id => {
    props.history.push("/praxes/" + id);
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  const [values, setValues] = useState({
    presencasPraxes: [],
    percentagem: -1
  })

  useEffect(() => {
    const fetchPresencasEspeciais = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/presencasPraxe/especial/${selectedBestaId}`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        presencasPraxes: resultado,
      });
    }

    fetchPresencasEspeciais();
  }, [selectedBestaId])

  const loading = values.presencasPraxes.length === 0

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Praxes Especiais</Typography>
          <Typography className={classes.secondaryHeading}>
            {values.percentagem}{'%'}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {loading ? null :
                <TableBody>
                  {stableSort(Array.from(values.presencasPraxes), getSorting(order, orderBy))
                    .map((praxe, i) => {
                      return (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={praxe.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography className={classes.nameText} variant="h5">
                          {praxe.nome}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.nameText} variant="body1">
                        { praxe.jantar == 1
                          ? <PresenteIcon />
                          : <FaltaIcon /> }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={event => handleVerPraxe(praxe.id)}
                      >
                        Ver Diário de Praxe <ArrowRightIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                  );
                    })}
                </TableBody>}
              </Table>
            </div>
          </PerfectScrollbar>
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};

export default withRouter(PraxesEspeciais);
