import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import locais from "../../data/locais";
import {
  ProcurarLocais,
  EditarLocal,
  CriarLocal,
  ListaLocais,
  Historico
} from "./components";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  list: {
    marginTop: theme.spacing(2)
  },
  editarButton: {
    marginRight: theme.spacing(1)
  }
}));

const Locais = props => {
  const classes = useStyles();

  const [values, setValues] = useState({
    openEditar: false,
    openCriar: false,
    selectedIndex: '',
    selectedLocalId: '',
    update: false,
    filtro: ''
  });

  const [data, setData] = useState({ locais: [] });

  useEffect(() => {
    var id = props.match.params.id;

    const fetchLocais = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      var resultado = await fetch(`${API_URL}/v1/locais`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())

      // Ordena a lista de locais por ordem alfabética
      resultado = resultado.sort(function(a, b){
        var textA = a.local.toUpperCase();
        var textB = b.local.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      // De acordo com o id do local, retorna o índice da lista
      // em que este se encontra (necessário para saber qual o elemento deverá
      // estar selecionado)
      const index = resultado.findIndex(function(item, i){
        return item.id == id
      })

      setValues({
        ...values,
        selectedIndex: index,
        selectedLocalId: id,
      });

      setData({
        locais: resultado
      });
    };
    fetchLocais();
  }, [values.update]);

  const handleSelect = (selectedIndex, selectedLocalId) => {
    setValues({
      ...values,
      selectedIndex,
      selectedLocalId,
    });
    props.history.push("/locais/" + selectedLocalId);
  };

  const handleSelectFiltro = event => {
    setValues({
      ...values,
      filtro: event.target.value
    })
  }

  const handleOpenEditar = () => {
    setValues({
      ...values,
      openEditar: true
    });
  };

  const handleCloseEditar = async (confirmar, local) => {
    if(confirmar) {
      // Os atributos do engenheiro a editar são definidos no componente
      // Editar Local. Este passa-os como argumento no objeto "local",
      // juntamente com a flag "confirmar", que indica se o local deve ser
      // editado na base de dados ou não
      const body = {
        'id': values.selectedLocalId,
        'local': local.local
      }
      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/locais/${values.selectedLocalId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(response => response.json())
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardado
    // no estado da componente e fechamos a página de Editar Local
    setValues({
      ...values,
      update: true,
      openEditar: false,
    })
  }

  const handleOpenCriar = () => {
    setValues({
      ...values,
      openCriar: true
    });
  };

  const handleCloseCriar = async (confirmar, local) => {
    if(confirmar) {
      // Os atributos do engenheiro a adicionar são definidos no componente
      // Criar Local. Este passa-os como argumento no objeto "local",
      // juntamente com a flag "confirmar", que indica se o local deve ser
      // inserido na base de dados ou não
      const body = {
        'local': local.local
      }

      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/locais`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(response => response.json())
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Adicionar Besta
    setValues({
      ...values,
      update: true,
      openCriar: false,
    })
  };

  const loading = data.locais.length === 0;

  return (
    <DashboardLayout title="Locais">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={3} sm={4} xl={3} xs={5}>
            <ProcurarLocais
              filtro={values.filtro}
              onHandleSelectFiltro={handleSelectFiltro}
            />
          </Grid>
          { loading
            ? null
            : <Grid
                item
                lg={9}
                sm={8}
                xl={9}
                xs={7}
                align="right"
                container
                justify="flex-end"
              >
                <EditarLocal
                  openEditar={values.openEditar}
                  onHandleOpenEditar={handleOpenEditar}
                  onHandleCloseEditar={handleCloseEditar}
                  selectedLocalId={values.selectedLocalId}
                  locais={data.locais}
                />
                <CriarLocal
                  openCriar={values.openCriar}
                  onHandleOpenCriar={handleOpenCriar}
                  onHandleCloseCriar={handleCloseCriar}
                />
              </Grid>
          }
        </Grid>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            { loading
              ? <CircularProgress/>
              : <ListaLocais
                  onHandleSelect={handleSelect}
                  selectedIndex={values.selectedIndex}
                  filtro={values.filtro}
                  locais={data.locais}
                />
            }
          </Grid>
          <Grid item lg={9} sm={8} xl={9} xs={12}>
            { loading
              ? <CircularProgress/>
              : <Historico
                  onHandleSelect={handleSelect}
                  selectedLocalId={values.selectedLocalId}
                  filtro={values.filtro}
                  locais={data.locais}
                />
            }
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default withRouter(Locais);
