import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { checkNullReturnBlank, validateDataReact } from '../../../../../../helpers/helperFunctions'

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Divider,
  Avatar,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  margin: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1)
  },
  avatar: {
    marginBottom: theme.spacing(2),
    height: "125px",
    width: "125px",
    flexShrink: 0,
    flexGrow: 0
  }
}));

const DadosPessoais = props => {
  const {
    onHandleSelect,
    onHandleDataNascimento,
    ...values
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          disabled
          label="Número de Disquete"
          margin="dense"
          name="numDisquete"
          onChange={onHandleSelect}
          value={values.numDisquete}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          autoFocus
          fullWidth
          label="Nome de Praxe"
          margin="dense"
          name="nomePraxe"
          onChange={onHandleSelect}
          value={values.nomePraxe}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Primeiro Nome"
          margin="dense"
          name="primeiroNome"
          onChange={onHandleSelect}
          value={values.primeiroNome}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Último Nome"
          margin="dense"
          name="ultimoNome"
          onChange={onHandleSelect}
          value={values.ultimoNome}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Outros Nomes"
          margin="dense"
          name="outrosNomes"
          onChange={onHandleSelect}
          value={values.outrosNomes}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin="dense"
            inputVariant="outlined"
            label="Data de Nascimento"
            name="dataNascimento"
            value={values.dataNascimento}
            onChange={onHandleDataNascimento}
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

const MoradaContactos = props => {
  const {
    onHandleSelect,
    ...values
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Cidade"
          margin="dense"
          name="cidade"
          onChange={onHandleSelect}
          value={values.cidade}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Freguesia"
          margin="dense"
          name="freguesia"
          onChange={onHandleSelect}
          value={values.freguesia}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Rua"
          margin="dense"
          name="rua"
          onChange={onHandleSelect}
          value={values.rua}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Email"
          margin="dense"
          name="email"
          onChange={onHandleSelect}
          value={values.email}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Telemóvel"
          margin="dense"
          name="telemovel"
          onChange={onHandleSelect}
          value={values.telemovel}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Telemóvel de Emergência"
          margin="dense"
          name="telemovelEmergencia"
          onChange={onHandleSelect}
          value={values.telemovelEmergencia}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Facebook ID"
          margin="dense"
          name="facebookID"
          onChange={onHandleSelect}
          value={values.facebookID}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

const UniversidadePraxe = props => {
  const {
    onHandleSelect,
    onHandleDiaDisquete,
    onHandleDataAntiPraxe,
    ...values
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Número de Aluno"
          margin="dense"
          name="numAluno"
          onChange={onHandleSelect}
          value={values.numAluno}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          label="Horário"
          margin="dense"
          name="horario"
          onChange={onHandleSelect}
          value={values.horario}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            required
            margin="dense"
            inputVariant="outlined"
            label="Data em que Recebeu a Disquete"
            name="diaDisquete"
            value={values.diaDisquete}
            onChange={onHandleDiaDisquete}
            format="dd/MM/yyyy"
            error={values.diaDisquete == null ? true : false}
            helperText={values.diaDisquete == null ? "Campo de preenchimento obrigatório" : ""}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          select
          label="Na Praxe"
          name="naPraxe"
          value={values.naPraxe}
          onChange={onHandleSelect}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
        >
          <MenuItem value={1}>Sim</MenuItem>
          <MenuItem value={0}>Não</MenuItem>
        </TextField>
      </Grid>
      <Grid item md={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            margin="dense"
            inputVariant="outlined"
            label="Data em que Entregou a Disquete"
            name="dataAntiPraxe"
            value={values.dataAntiPraxe}
            onChange={onHandleDataAntiPraxe}
            format="dd/MM/yyyy"
            disabled={values.naPraxe ? true : false}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          select
          label="Assinou a Folha"
          value={values.assinouFolha}
          name="assinouFolha"
          onChange={onHandleSelect}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          margin="dense"
          variant="outlined"
          disabled={values.naPraxe ? true : false}
        >
          <MenuItem value={1}>Sim</MenuItem>
          <MenuItem value={0}>Não</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

const Anexos = props => {
  const {
    onHandleSelectFile,
    ...values
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <input
          accept="image/*"
          type="file"
          name="questionario"
          id="questionario-input"
          style={{ display: 'none' }}
          onChange={onHandleSelectFile}
        />
        <label htmlFor="questionario-input">
          <Button
            fullWidth
            color="primary"
            margin="dense"
            variant="outlined"
            component="span"
          >
            Questionário
            <CloudUploadIcon className={classes.rightIcon} />
          </Button>
        </label>
        <Typography align="center" variant="body1">
          { values.questionario == null
            ? ''
            : values.questionario.name
          }
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <input
          accept="image/*"
          type="file"
          name="autorizacaoPais"
          id="autorizacao-input"
          style={{ display: 'none' }}
          onChange={onHandleSelectFile}
        />
        <label htmlFor="autorizacao-input">
          <Button
            fullWidth
            color="primary"
            margin="dense"
            variant="outlined"
            component="span"
          >
            Autorização dos Pais
            <CloudUploadIcon className={classes.rightIcon} />
          </Button>
        </label>
        <Typography align="center" variant="body1">
          { values.autorizacaoPais == null
            ? ''
            : values.autorizacaoPais.name
          }
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <input
          accept="image/*"
          type="file"
          name="folha"
          id="folhaAP-input"
          style={{ display: 'none' }}
          onChange={onHandleSelectFile}
          disabled={values.assinouFolha ? false : true}
        />
        <label htmlFor="folhaAP-input">
          <Button
            fullWidth
            color="primary"
            margin="dense"
            variant="outlined"
            component="span"
            disabled={values.assinouFolha ? false : true}
          >
            Folha de Anti-Praxe
            <CloudUploadIcon className={classes.rightIcon} />
          </Button>
        </label>
        <Typography align="center" variant="body1">
          { values.folha == null
            ? ''
            : values.folha.name
          }
        </Typography>
      </Grid>
    </Grid>
  );
};

const EditarPerfil = props => {
  const {
    besta,
    openEditar,
    onHandleOpenEditar,
    onHandleCloseEditar
  } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    numDisquete: '',
    diaDisquete: new Date(),
  });

  useEffect(() => {
    setValues({
      numDisquete: checkNullReturnBlank(besta.numDisquete),
      nomePraxe: checkNullReturnBlank(besta.nomePraxe),
      diaDisquete: validateDataReact(besta.diaDisquete),
      questionario: checkNullReturnBlank(besta.questionario),
      primeiroNome: checkNullReturnBlank(besta.primeiroNome),
      ultimoNome: checkNullReturnBlank(besta.ultimoNome),
      outrosNomes: checkNullReturnBlank(besta.outrosNomes),
      foto: checkNullReturnBlank(besta.foto),
      //fotoPreview: URL.createObjectURL(besta.foto),
      fotoPreview: '',
      dataNascimento: validateDataReact(besta.dataNascimento),
      telemovel: checkNullReturnBlank(besta.telemovel),
      telemovelEmergencia: checkNullReturnBlank(besta.telemovelEmergencia),
      email: checkNullReturnBlank(besta.email),
      cidade: checkNullReturnBlank(besta.cidade),
      freguesia: checkNullReturnBlank(besta.freguesia),
      rua: checkNullReturnBlank(besta.rua),
      facebookID: checkNullReturnBlank(besta.facebookID),
      numAluno: checkNullReturnBlank(besta.numAluno),
      horario: checkNullReturnBlank(besta.horario),
      autorizacaoPais: checkNullReturnBlank(besta.autorizacaoPais),
      naPraxe: checkNullReturnBlank(besta.naPraxe),
      dataAntiPraxe: validateDataReact(besta.dataAntiPraxe),
      assinouFolha: checkNullReturnBlank(besta.assinouFolha),
      folha: checkNullReturnBlank(besta.folha)
    })
  }, [openEditar])

  const handleSelect = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSelectFoto = event => {
    setValues({
      ...values,
      foto: event.target.files[0],
      fotoPreview: URL.createObjectURL(event.target.files[0])
    })
  }

  const handleSelectFile = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.files[0]
    })
  }

  const handleRemoveFile = (e, propriedade) => {
    setValues({
      ...values,
      [propriedade]: ''
    })
  }

  const handleDataNascimento = dataNascimento => {
    setValues({
      ...values,
      dataNascimento
    });
  };

  const handleDiaDisquete = diaDisquete => {
    setValues({
      ...values,
      diaDisquete
    });
  };

  const handleDataAntiPraxe = dataAntiPraxe => {
    setValues({
      ...values,
      dataAntiPraxe
    });
  };

  const handleLimparCampos = () => {
    setValues({
      numDisquete: checkNullReturnBlank(besta.numDisquete),
      nomePraxe: checkNullReturnBlank(besta.nomePraxe),
      diaDisquete: validateDataReact(besta.diaDisquete),
      questionario: checkNullReturnBlank(besta.questionario),
      primeiroNome: checkNullReturnBlank(besta.primeiroNome),
      ultimoNome: checkNullReturnBlank(besta.ultimoNome),
      outrosNomes: checkNullReturnBlank(besta.outrosNomes),
      foto: checkNullReturnBlank(besta.foto),
      //fotoPreview: URL.createObjectURL(besta.foto),
      dataNascimento: validateDataReact(besta.dataNascimento),
      telemovel: checkNullReturnBlank(besta.telemovel),
      telemovelEmergencia: checkNullReturnBlank(besta.telemovelEmergencia),
      email: checkNullReturnBlank(besta.email),
      cidade: checkNullReturnBlank(besta.cidade),
      freguesia: checkNullReturnBlank(besta.freguesia),
      rua: checkNullReturnBlank(besta.rua),
      facebookID: checkNullReturnBlank(besta.facebookID),
      numAluno: checkNullReturnBlank(besta.numAluno),
      horario: checkNullReturnBlank(besta.horario),
      autorizacaoPais: checkNullReturnBlank(besta.autorizacaoPais),
      naPraxe: checkNullReturnBlank(besta.naPraxe),
      dataAntiPraxe: validateDataReact(besta.dataAntiPraxe),
      assinouFolha: checkNullReturnBlank(besta.assinouFolha),
      folha: checkNullReturnBlank(besta.folha)
    })
  };

  return (
    <div>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={onHandleOpenEditar}
      >
        Editar Perfil
      </Button>
      <Dialog
        open={openEditar}
        onClose={event => {
            onHandleCloseEditar(false);
            handleLimparCampos();
          }
        }
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Editar Perfil</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} src={values.fotoPreview} />
          </div>
          <div className={classes.button}>
            <input
              accept="image/*"
              type="file"
              id="foto-input"
              name="foto"
              style={{ display: 'none' }}
              onChange={handleSelectFoto}
            />
            <label htmlFor="foto-input">
              <Button
                color="primary"
                size="small"
                variant="text"
                component="span"
              >
                Adicionar Foto
              </Button>
            </label>
            <Button
              size="small"
              variant="text"
              component="span"
              onClick={(e) => {
                  handleRemoveFile(e, 'foto');
                  handleRemoveFile(e, 'fotoPreview');
                }
              }
            >
              Remover Foto
            </Button>
          </div>
          <DialogContentText align="center" variant="h6">
            Dados Pessoais
          </DialogContentText>
          <DadosPessoais
            onHandleSelect={handleSelect}
            onHandleDataNascimento={handleDataNascimento}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Morada e Contactos
          </DialogContentText>
          <MoradaContactos
            onHandleSelect={handleSelect}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Universidade e Praxe
          </DialogContentText>
          <UniversidadePraxe
            onHandleSelect={handleSelect}
            onHandleDiaDisquete={handleDiaDisquete}
            onHandleDataAntiPraxe={handleDataAntiPraxe}
            {...values}
          />
          <DialogContentText
            align="center"
            variant="h6"
            className={classes.margin}
          >
            Anexos
          </DialogContentText>
          <Anexos
            onHandleSelectFile={handleSelectFile}
            {...values}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={event => {
                onHandleCloseEditar(false);
                handleLimparCampos();
            }
          }
          color="primary">
            Cancelar
          </Button>
          <Button
            onClick={event => onHandleCloseEditar(true, values)}
            color="primary"
            disabled={values.numDisquete.length === 0
              || values.diaDisquete == null ? true : false}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditarPerfil;
