import React from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Portlet, PortletContent, PortletFooter } from "../../../../components";
import { EditarPerfil } from "./components";
import { SocialIcon } from "react-social-icons";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Avatar, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    marginTop: theme.spacing(1),
    display: "flex"
  },
  text: {
    color: theme.palette.text.secondary
  },
  avatar: {
    marginLeft: "auto",
    height: "150px",
    width: "150px"
  },
  iconBackground: {
    marginLeft: 5,
    backgroundColor: theme.palette.primary.main,
    height: 30,
    width: 30,
    alignItems: "center"
  },
  icon: {
    height: 15,
    width: 15
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
}));

const PrimeiraColuna = props => {
  const { engenheiro } = props;

  const classes = useStyles();

  return (
    <Grid item md={12} xs={12} sm={12} lg={7}>
      <Typography className={classes.text} variant="body1">
        <strong>
          {engenheiro.primeiroNome} {engenheiro.ultimoNome}
        </strong>{" "}
        {engenheiro.outrosNomes}
      </Typography>
      <Typography className={classes.text} variant="body1">
        <Moment diff={engenheiro.dataNascimento} unit="years">
          {new Date()}
        </Moment>{" "}
        anos (<Moment format="D [de] MMMM">{engenheiro.dataNascimento}</Moment>)
      </Typography>
      <Typography className={classes.text} variant="body1">
        {engenheiro.cidade}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {engenheiro.freguesia}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {engenheiro.rua}
      </Typography>
    </Grid>
  );
};

const SegundaColuna = props => {
  const { engenheiro } = props;

  const classes = useStyles();

  return (
    <Grid item md={12} xs={12} sm={12} lg={5}>
      <Typography className={classes.text} variant="body1">
        {engenheiro.telemovel}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {engenheiro.email}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {engenheiro.numAluno}
      </Typography>
    </Grid>
  );
};

const Perfil = props => {
  const { className, openEditar, selectedEngenheiroId, engenheiros, onHandleOpenEditar, onHandleCloseEditar,...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

  const engenheiro = engenheiros.find(
    engenheiro => engenheiro.id == selectedEngenheiroId
  );

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletContent>
        <div className={classes.header}>
          <Typography variant="h2" style={{ marginRight: 10 }}>
            {engenheiro.numDisquete} {engenheiro.nomePraxe}
          </Typography>
          <Typography
            className={classes.text}
            variant="h4"
            style={{ marginRight: 10 }}
          >
            {engenheiro.grauHierarquico}
          </Typography>
          <SocialIcon url={"https://facebook.com/" + engenheiro.facebookID} style={{ height: 30, width: 30 }} />
        </div>
        <div className={classes.details}>
          <div>
            <Grid container spacing={3}>
              <PrimeiraColuna className={className} engenheiro={engenheiro} />
              <SegundaColuna className={className} engenheiro={engenheiro} />
            </Grid>
          </div>
          <Avatar className={classes.avatar} src={IMAGES_URL + engenheiro.foto} />
        </div>
      </PortletContent>
      <PortletFooter>
        <EditarPerfil
          className={classes.item}
          engenheiro={engenheiro}
          openEditar={openEditar}
          onHandleOpenEditar={onHandleOpenEditar}
          onHandleCloseEditar={onHandleCloseEditar}
        />
      </PortletFooter>
    </Portlet>
  );
};

export default Perfil;
