import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import {
  FaceOutlined as BestasIcon,
  ArrowUpward as ArrowUpwardIcon
} from "@material-ui/icons";
import { Paper } from "../../../../components";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700
  },
  value: {
    marginTop: theme.spacing(1)
  },
  iconWrapper: {
    alignItems: "center",
    backgroundColor: "rgb(255, 99, 132)",
    borderRadius: "50%",
    display: "inline-flex",
    height: "4rem",
    justifyContent: "center",
    marginLeft: "auto",
    width: "4rem"
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem"
  },
  footer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  difference: {
    alignItems: "center",
    color: theme.palette.success.dark,
    display: "inline-flex",
    fontWeight: 700
  },
  caption: {
    marginLeft: theme.spacing(1)
  }
}));

const AntiPraxe = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const [values, setValues] = useState({
    antiPraxe: -1,
    percentagem: -1
  });

  useEffect(() => {
    const fetchData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/bestas/antiPraxe`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

      setValues({
        ...values,
        antiPraxe: resultado.antiPraxe,
        percentagem: (resultado.antiPraxe / resultado.total) * 100,
      });
    }
    fetchData();
  }, [])

  const loading = values.antiPraxe == -1

  return (
    <Paper {...rest} className={rootClassName}>
      { loading
        ? <CircularProgress />
        : <div className={classes.content}>
            <div className={classes.details}>
              <Typography className={classes.title} variant="body2">
                ANTI-PRAXE
              </Typography>
              <Typography className={classes.value} variant="h3">
                {values.antiPraxe} ({Math.round(values.percentagem)}%)
              </Typography>
            </div>
            <div className={classes.iconWrapper}>
              <BestasIcon className={classes.icon} />
            </div>
          </div>
      }
      <div className={classes.footer}></div>
    </Paper>
  );
};

export default AntiPraxe;
