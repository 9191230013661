import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "../../layouts";
import { Detalhes, EditarPerfil } from "./components";
import CircularProgress from '@material-ui/core/CircularProgress';
import { checkNullReturnBlank, validateDataSQL } from '../../helpers/helperFunctions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Perfil = props => {
  const classes = useStyles();

  const [values, setValues] = useState({
    loading: true,
    update: false,
  });

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("user")).id

    const fetchEngenheiro = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;

      const resultado =
        await fetch(`${API_URL}/v1/engenheiros/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
            'Content-Type': 'application/json'
          },
        })
          .then(response => {
            if(!response.ok) {
              props.history.push('/iniciar-sessao')
            }
            return response.json()
          })

        setValues({
          numDisquete: checkNullReturnBlank(resultado.numDisquete),
          nomePraxe: checkNullReturnBlank(resultado.nomePraxe),
          primeiroNome: checkNullReturnBlank(resultado.primeiroNome),
          ultimoNome: checkNullReturnBlank(resultado.ultimoNome),
          outrosNomes: checkNullReturnBlank(resultado.outrosNomes),
          foto: checkNullReturnBlank(resultado.foto),
          fotoPreview: IMAGES_URL + checkNullReturnBlank(resultado.foto),
          dataNascimento: checkNullReturnBlank(resultado.dataNascimento),
          telemovel: checkNullReturnBlank(resultado.telemovel),
          email: checkNullReturnBlank(resultado.email),
          cidade: checkNullReturnBlank(resultado.cidade),
          freguesia: checkNullReturnBlank(resultado.freguesia),
          rua: checkNullReturnBlank(resultado.rua),
          facebookID: checkNullReturnBlank(resultado.facebookID),
          numAluno: checkNullReturnBlank(resultado.numAluno),
          grauHierarquico: checkNullReturnBlank(resultado.grauHierarquico),
          loading: false,
          update: false
        })
      }
      fetchEngenheiro();
    }, [values.update]);

  const handleConfirmar = async (confirmar, engenheiro) => {
    const id = JSON.parse(localStorage.getItem("user")).id

    if(confirmar) {
      const body = {
        'numDisquete': parseInt(engenheiro.numDisquete),
        'nomePraxe': engenheiro.nomePraxe,
        'primeiroNome': engenheiro.primeiroNome,
        'ultimoNome': engenheiro.ultimoNome,
        'outrosNomes': engenheiro.outrosNomes,
        'dataNascimento': validateDataSQL(engenheiro.dataNascimento),
        'telemovel': engenheiro.telemovel,
        'email': engenheiro.email,
        'cidade': engenheiro.cidade,
        'freguesia': engenheiro.freguesia,
        'rua': engenheiro.rua,
        'facebookID': engenheiro.facebookID,
        'numAluno': engenheiro.numAluno,
        'grauHierarquico': engenheiro.grauHierarquico,
      }

      // TODO: We are ignoring photo updates for now.
      //
      // The correct way of doing this is to create a new API endpoint just for
      // photo uploading, where some identifier is returned (such as a UUID or
      // just a basic integer).
      // Once the user submits the form, the frontend should send the new user
      // information plus the new photo identifier.
      //
      // Example:
      //
      //     if (state.photo_id !== null) {
      //       body["photo_id"] = state.photo_id
      //     }

      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/engenheiros/${id}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
    }
    await new Promise(r => setTimeout(r, 1000));
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Editar Perfil
    setValues({
      ...values,
      update: !values.update,
    })
  }

  const handleSelectFoto = event => {
    setValues({
      ...values,
      foto: event.target.files[0],
      fotoPreview: URL.createObjectURL(event.target.files[0])
    })
  }

  const handleRemoveFile = (e, propriedade) => {
    setValues({
      ...values,
      [propriedade]: ''
    })
  }

  return (
    <DashboardLayout title="Perfil">
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            { values.loading
              ? <CircularProgress />
              : <Detalhes
                  user={values}
                  onHandleSelectFoto={handleSelectFoto}
                  onHandleRemoveFile={handleRemoveFile}
                />
            }
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            { values.loading
              ? <CircularProgress />
              : <EditarPerfil
                  user={values}
                  onHandleConfirmar={handleConfirmar}
              />
            }
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

export default Perfil;
