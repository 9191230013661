import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import {
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider
} from "@material-ui/core";
import { ExpandMoreOutlined as ExpandirIcon } from "@material-ui/icons";
import { Expansion } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  quote: {
    color: theme.palette.text.secondary
  },
  inline: {
    display: "inline"
  },
  list: {
    marginTop: theme.spacing(-3),
    width: "100%"
  }
}));

const Quotes = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Expansion>
        <ExpansionPanelSummary
          expandIcon={<ExpandirIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Últimas Quotes</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={classes.list}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Alt-Zé" src="/images/avatars/avatar_11.png" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h6"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Praxe de 30 de Setembro
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Alt-Zé
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      className={(classes.inline, classes.quote)}
                    >
                      {
                        " — Saiu mais cedo para ir para ir para aula de condução."
                      }
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Alt-Zé" src="/images/avatars/avatar_11.png" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="h6"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Reunião de 27 de Setembro
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      Alt-Zé
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      className={(classes.inline, classes.quote)}
                    >
                      {" — Faz parte do grupo dos aleijadinhos."}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </Expansion>
    </div>
  );
};

export default Quotes;
