import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Portlet,
  PortletHeader,
  PortletContent,
  PortletFooter
} from "../../../../components";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Button
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row-reverse"
  }
}));

const Notificacoes = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Portlet {...rest} className={clsx(classes.root, className)}>
      <form>
        <PortletHeader>
          <Typography variant="h5">Notificações</Typography>
        </PortletHeader>
        <PortletContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Notificações Push"
              />
            </Grid>
          </Grid>
        </PortletContent>
        <PortletFooter className={classes.footer}>
          <Button color="primary" disabled>Confirmar</Button>
        </PortletFooter>
      </form>
    </Portlet>
  );
};

Notificacoes.propTypes = {
  className: PropTypes.string
};

export default Notificacoes;
