import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  details: {
    display: "flex"
  },
  info: {},
  locationText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    marginLeft: "auto",
    height: "150px",
    width: "150px",
    flexShrink: 0,
    flexGrow: 0
  },
  progressWrapper: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const EditarLocal = props => {
  const {
    openCriar,
    onHandleOpenCriar,
    onHandleCloseCriar
  } = props;

  const classes = useStyles();

  const [values, setValues] = useState(0);

  const handleSelect = event => {
    setValues({
      ...values,
      local: event.target.value
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={onHandleOpenCriar}
      >
        Criar Local
      </Button>
      <Dialog
        open={openCriar}
        onClose={event => onHandleCloseCriar(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Criar Local</DialogTitle>
        <DialogContent>
          <DialogContentText>Indica o nome do local.</DialogContentText>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              fullWidth
              autoFocus={true}
              label="Nome do Local"
              className={classes.textField}
              value={values.local}
              onChange={handleSelect}
              margin="normal"
              variant="outlined"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => onHandleCloseCriar(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={event => onHandleCloseCriar(true, values)} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditarLocal;
