import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Paper } from "../../../../components";
import { EditarLocalizacao } from "./components";
import { LocationOnOutlined as LocaisIcon } from "@material-ui/icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import { validateDataReact } from '../../../../helpers/helperFunctions'
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700
  },
  value: {
    marginTop: theme.spacing(1)
  },
  iconWrapper: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "inline-flex",
    height: "4rem",
    justifyContent: "center",
    marginLeft: "auto",
    width: "4rem",
    flexShrink: 0
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem"
  },
  footer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  difference: {
    alignItems: "center",
    color: theme.palette.danger.dark,
    display: "inline-flex",
    fontWeight: 700
  },
  caption: {
    marginLeft: theme.spacing(1)
  }
}));

const Local = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const [values, setValues] = useState({
    selectedLocal: '',
    selectedIdLocal: '',
    local: '',
    user: '',
    timestamp: new Date(),
    open: false,
    update: false
  });

  useEffect(() => {
    const fetchLocalizacao = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      const resultado = await fetch(`${API_URL}/v1/locaisPraxe/ultimoLocal`, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })

        setValues({
          ...values,
          selectedLocalId: resultado["locals"].idLocal,
          local: resultado["locals"].local,
          user: resultado["engenheiros"].nomePraxe,
          timestamp: validateDataReact(resultado["locais_praxes"].created_at)
        })
    }
    fetchLocalizacao();
  }, [values.update])

  const handleOpen = event => {
    setValues({
      ...values,
      open: true
    });
  };

  const handleSelect = event => {
    setValues({
      ...values,
      selectedLocalId: event.target.value
    });
  };

  const handleClose = async confirmar => {
    const editarLocalizacao = async () => {
      const body = {
        'idEngenheiro': JSON.parse(localStorage.getItem('user')).id,
        'idLocal': values.selectedLocalId
      }
      const API_URL = process.env.REACT_APP_API_URL;
      fetch(`${API_URL}/v1/locaisPraxe`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('access_token')).token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => {
          if(!response.ok) {
            props.history.push('/iniciar-sessao')
          }
          return response.json()
        })
    }

    if(confirmar) {
      editarLocalizacao();
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    // Forçamos o re-rendering da página através da flag "update" guardada
    // no estado da componente e fechamos a página de Editar Praxe
    setValues({
      ...values,
      update: !values.update,
      open: false
    })
  }

  const loading = values.local === ''

  return (
    loading ? null :
    <Paper className={rootClassName}>
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography className={classes.title} variant="body2">
            LOCALIZAÇÃO DO BLOCO
          </Typography>
          <Typography className={classes.value} variant="h3">
            {values.local}
          </Typography>
        </div>
        <div className={classes.iconWrapper}>
          <LocaisIcon className={classes.icon} />
        </div>
      </div>
      <div className={classes.footer}>
        <Typography className={classes.title} variant="h5">
          <Moment fromNow>{values.timestamp}</Moment> por {values.user}
        </Typography>
      </div>
      <div className={classes.footer}>
        <EditarLocalizacao
          classes={classes}
          open={values.open}
          local={values.local}
          selectedLocalId={values.selectedLocalId}
          onHandleSelect={handleSelect}
          onHandleOpen={handleOpen}
          onHandleClose={handleClose}
        />
      </div>
    </Paper>
  );
};

export default withRouter(Local);
