import React from "react";
import Moment from "react-moment";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import { Portlet, PortletContent } from "../../../../components";

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    marginTop: theme.spacing(1),
    display: "flex"
  },
  reportTitle: {
    marginTop: theme.spacing(2)
  },
  reportText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  planeamento: {
    marginLeft: theme.spacing(2)
  },
  text: {
    color: theme.palette.text.secondary
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: "auto",
    height: "150px",
    width: "150px"
  },
  iconBackground: {
    marginLeft: 5,
    backgroundColor: theme.palette.primary.main,
    height: 30,
    width: 30,
    alignItems: "center"
  },
  icon: {
    height: 15,
    width: 15
  },
  header: {
    display: "flex",
    alignItems: "center"
  }
}));

const Detalhes = props => {
  const { className, selectedPraxeId, praxes, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const praxe = praxes.find(praxe => praxe.id === selectedPraxeId);
  const planeamento = praxe.planeamento;

  return (
    <Portlet {...rest} className={rootClassName}>
      <PortletContent>
        <div className={classes.header}>
          <Typography variant="h2">
            {praxe.nome} (<Moment format="dddd">{praxe.data}</Moment>)
          </Typography>
        </div>
        <div className={classes.details}>
          <div>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12} sm={12} lg={12}>
                <Typography className={classes.text} variant="body1">
                  Realizada <Moment fromNow>{praxe.data}</Moment>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.reportTitle}>
          <Typography variant="h5">Nomes de Praxe</Typography>
          <ul className={classes.planeamento}>
            <Typography className={classes.reportText} variant="body1">
              {planeamento.map((topico, i) => (
                <li>{topico}</li>
              ))}
            </Typography>
          </ul>
        </div>
        <div className={classes.reportTitle}>
          <Typography variant="h5">Diário de Praxe</Typography>
          <Typography className={classes.reportText} variant="body1">
            {praxe.report}
          </Typography>
        </div>
      </PortletContent>
    </Portlet>
  );
};

Detalhes.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  praxe: PropTypes.array.isRequired
};

export default Detalhes;
