import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Paper } from "../../../../components";
import { EditarPraxeEspecial } from "./components";
import { EventNoteOutlined as CalendarioIcon } from "@material-ui/icons";
import ApiCalendar from 'react-google-calendar-api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    alignItems: "center",
    display: "flex"
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700
  },
  value: {
    marginTop: theme.spacing(1)
  },
  iconWrapper: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "inline-flex",
    height: "4rem",
    justifyContent: "center",
    marginLeft: "auto",
    width: "4rem",
    flexShrink: 0
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    height: "2rem",
    width: "2rem"
  },
  footer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center"
  },
  difference: {
    alignItems: "center",
    color: theme.palette.danger.dark,
    display: "inline-flex",
    fontWeight: 700
  },
  caption: {
    marginLeft: theme.spacing(1)
  }
}));

const PraxeEspecial = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const [values, setValues] = useState({
    open: false,
    praxeEspecial: "Por marcar",
    date: new Date(8640000000000000),
    selectedPraxeEspecial: '',
    selectedDate: new Date()
  });

  useEffect(() => {
      if (ApiCalendar.sign)
          ApiCalendar.listUpcomingEvents(10)
              .then(({result}) => {
                  const res = result.items.filter(r => r.description === "PraxeEspecial")[0];
                  if (res != undefined) {
                      setValues({
                          praxeEspecial: res.summary,
                          date: Date.parse(res.start.dateTime)
                      });
                  }
              })
  },[]);

  const handleOpen = () => {
    setValues({
      ...values,
      open: true
    });
  };

  const handleSelectPraxeEspecial = event => {
    setValues({
      ...values,
      selectedPraxeEspecial: event.target.value
    });
  };

  const handleSelectDate = selectedDate => {
    setValues({
      ...values,
      selectedDate
    });
  };

  const handleClose = confirmar => {
    setValues({
      ...values,
      open: false
    });
    if (confirmar) {
      const newDate = new Date(new Date().toDateString());
      if (values.selectedDate <= values.date && values.selectedDate >= newDate) {
          setValues({
              ...values,
              open: false,
              praxeEspecial: values.selectedPraxeEspecial,
              date: values.selectedDate
          });
      } 
      const event = {
          start: values.selectedDate,
          end: values.selectedDate,
          start: {
              dateTime: values.selectedDate,
              timeZone: "Europe/Paris",
          },
          end: {
              dateTime: new Date(values.selectedDate.getTime() + 1000*60000),
              timeZone: "Europe/Paris",
          },
          summary: values.selectedPraxeEspecial,
          description: "PraxeEspecial"
      }
      ApiCalendar.createEvent(event)
          .then((result) => {
              console.log(result);
          })
          .catch((error) => {
              console.log(error);
          });
    }
  };

  return (
    <Paper {...rest} className={rootClassName}>
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography className={classes.title} variant="body2">
            PRÓXIMA PRAXE ESPECIAL
          </Typography>
          <Typography className={classes.value} variant="h3">
            {values.praxeEspecial}
          </Typography>
        </div>
        <div className={classes.iconWrapper}>
          <CalendarioIcon className={classes.icon} />
        </div>
      </div>
      <div className={classes.footer}>
        <Typography className={classes.title} variant="h5">
          <Moment format="D [de] MMMM">{values.date}</Moment>
        </Typography>
      </div>
      <div className={classes.footer}>
        <EditarPraxeEspecial
          classes={classes}
          open={values.open}
          selectedPraxeEspecial={values.selectedPraxeEspecial}
          selectedDate={values.selectedDate}
          onHandleSelectPraxeEspecial={handleSelectPraxeEspecial}
          onHandleSelectDate={handleSelectDate}
          onHandleOpen={handleOpen}
          onHandleClose={handleClose}
        />
      </div>
    </Paper>
  );
};

export default PraxeEspecial;
